import { createAction, props } from '@ngrx/store';
import { ClientsDropdownList } from '../models/clients-dropdown-list.models';

export const fetchClientsDropdownList = createAction(
	'[ClientsDropdownList/API] Fetch Clients Dropdown List',
);

export const updateClientsDropdownList = createAction(
	'[ClientsDropdownList] Update Clients Dropdown List',
	props<{ universities: ClientsDropdownList[] }>(),
);

export const loadClientsDropdownListError = createAction(
	'[ClientsDropdownList] Load Clients Dropdown List Error',
	props<any>(),
);
