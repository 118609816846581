@if (showTemplate) {
	<div class="flex gap-3 pt-4 sm:mt-6 sm:border-t sm:pt-6">
		@if (featuredCalls && phoneNumber) {
			<uc-call-uni-btn
				class="flex-1 w-full"
				customStyles="w-full"
				buttonText="Call University"
				mobileButtonText="Call University"
				[phoneNumber]="phoneNumber"
				[uniId]="uniId"
				[uniName]="uniName"
				sourceType="uni"
				[source]="uniName"
				componentName="University Listing"
				[cleSearchOrderPosition]="cleSearchOrderPosition"
			/>
		}
		@if (featuredRegisteredInterest) {
			<uc-register-interest-btn
				[uniId]="uniId"
				sourceType="uni"
				class="flex-1 w-full"
				customStyles="w-full"
				componentName="University Listing"
				[cleSearchOrderPosition]="cleSearchOrderPosition"
			/>
		}
		@if (featuredClearingVisitWebsite && externalUrl) {
			<uc-clearing-visit-website-btn
				class="flex-1 hidden w-full sm:inline-block"
				customStyles="w-full hidden sm:inline-block"
				[externalUrl]="externalUrl"
				componentName="University Listing"
				[uniId]="uniId"
				buttonText="Visit Website"
				sourceType="uni"
				[source]="uniName"
				[uniName]="uniName"
				[cleSearchOrderPosition]="cleSearchOrderPosition"
			/>
		}
	</div>
}
