@if (showTitle) {
	<h3 class="mb-6 text-xl font-semibold text-black">Shipping Address</h3>
}
<div [formGroup]="parentForm">
	<div
		formGroupName="addressDetailsForm"
		class="grid gap-6 pb-6 border-b border-gray-200"
	>
		<div>
			<uc-input-field
				formControlName="street"
				inputType="text"
				controlName="street"
				placeholder="Home Address*"
				autocomplete="address-line1"
				labelText="Home Address"
				[formSubmitted]="formSubmitted"
			/>
		</div>

		<!-- for prospectus order -->
		<div class="grid gap-6 lg:grid-cols-2">
			<div>
				<!-- city -->
				<uc-input-field
					formControlName="city"
					inputType="text"
					controlName="city"
					placeholder="Town/City*"
					autocomplete="address-level2"
					labelText="Town/City"
					[formSubmitted]="formSubmitted"
				/>
			</div>

			<div>
				<!-- show postcode for UK users -->
				@if (UKResident) {
					<div formArrayName="postcode">
						@for (
							control of postcode.controls;
							track control;
							let i = $index
						) {
							<div>
								<div [formGroupName]="i">
									<uc-postcode-input
										formControlName="postcodeData"
										controlName="postcodeData"
										placeholderText="UK Postcode*"
										[formSubmitted]="formSubmitted"
										[hideResidencySwitch]="hideResidencySwitch"
										(UKResident)="onClickNotUk($event)"
									/>
								</div>
							</div>
						}
					</div>
				}
				@if (!UKResident) {
					<div>
						<uc-custom-select
							[data]="countries"
							formControlName="country"
							controlName="country"
							labelName="Country"
							placeholder="Country*"
							errorMsg="Please select your country of residence."
							[isArrayOfObjects]="true"
							key="name"
							[disableTick]="false"
							[formSubmitted]="formSubmitted"
						/>
						<div class="flex justify-end">
							<button
								(click)="onClickFromUk()"
								type="button"
								class="mt-2 text-sm cursor-pointer blue-link"
							>
								From the UK?
							</button>
						</div>
					</div>
				}
			</div>
		</div>
		<!-- show region for non-UK users -->
		@if (!UKResident) {
			<div formArrayName="region">
				@if (region.controls) {
					<div>
						@for (control of region.controls; track control; let i = $index) {
							<div>
								<div [formGroupName]="i">
									<uc-custom-select
										[data]="regions"
										formControlName="regionData"
										controlName="regionData"
										labelName="Region"
										placeholder="Region*"
										errorMsg="Please select a region."
										[isArrayOfObjects]="true"
										key="name"
										[disableTick]="false"
										[formSubmitted]="formSubmitted"
									/>
								</div>
							</div>
						}
					</div>
				}
				@if (!region.controls.length) {
					<div>
						<div
							class="relative flex px-3 py-2 bg-white border rounded-md shadow-sm"
						>
							<div class="flex items-center justify-between w-full">
								<input
									placeholder="Region"
									class="w-full border-none outline-none disabled:bg-transparent"
									disabled
								/>
								<uc-arrow-down-svg customStyles="text-gray-400" />
							</div>
						</div>
					</div>
				}
			</div>
		}
	</div>
</div>
