import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ProductHandlerService, PromoSlotGTMService } from '@uc/web/core';
import { ClearingVisitWebsiteBtnApiService } from '@uc/web/shared/data-access';
import { PromoSlotComponentNameType } from '@uc/web/shared/data-models';

@Injectable({
	providedIn: 'root',
})
export class PromoSlotService {
	private _document = inject(DOCUMENT);
	private _router = inject(Router);
	private _clearingVisitWebsiteBtnApiSrv = inject(ClearingVisitWebsiteBtnApiService);
	private _productHandlerSrv = inject(ProductHandlerService);
	private _promoSlotGTMSrv = inject(PromoSlotGTMService);
	private _window = this._document.defaultView as Window & typeof globalThis;

	handlePromoSlotClick(
		link: string,
		componentName: PromoSlotComponentNameType,
		cardIndex: number,
		sectionIndex: 1 | 2 | null,
		uniId?: number,
		uniName?: string,
		isTracked?: boolean,
		isInAppBrowser = false,
	) {
		// tracks promo slot position
		this._promoSlotGTMSrv.clickPromoSlot(
			this._getPromoSlotNumber(sectionIndex, cardIndex),
			componentName,
			uniId,
			uniName,
		);

		if (!isInAppBrowser) {
			this._navigateBasedOnLinkType(link, uniId, uniName, isTracked);
		}
	}

	private _navigateBasedOnLinkType(
		link: string,
		uniId?: number,
		uniName?: string,
		isTracked?: boolean,
	) {
		//external link
		if (!link.includes('universitycompare.com')) {
			this._trackVisitWebsiteClick(uniId, uniName, isTracked);
			this._window?.open(link, '_blank');
			return;
		}
		// internal link
		if (link.includes('order')) {
			this._router.navigate(['/select'], {
				queryParams: { uni: 'bulk' },
				queryParamsHandling: 'merge',
			});
			return;
		}

		const url = new URL(link);
		const path = url.pathname;
		this._router.navigate([path]);
	}

	// tracks website clicks for clearing cards only && when tracking is enabled
	private _trackVisitWebsiteClick(
		uniId?: number,
		uniName?: string,
		isTracked?: boolean,
	) {
		if (!isTracked || !uniId || !uniName) return;

		const name = this._productHandlerSrv.configureSource(uniName, 'uni');
		this._clearingVisitWebsiteBtnApiSrv.clearingVisitWebsiteClick(uniId, name);
	}

	private _getPromoSlotNumber(sectionIndex: null | 1 | 2, cardIndex: number): number {
		const firstSectionBaseIndex = 1;
		const secondSectionBaseIndex = 5;
		const baseIndex =
			sectionIndex === 2 ? secondSectionBaseIndex : firstSectionBaseIndex;

		return cardIndex + baseIndex;
	}
}
