import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Product, ProductEnum, ProductId } from '@uc/web/shared/data-models';
import { CustomCookieService } from '@uc/shared/authentication';
import { EnvironmentApiService } from '@uc/utilities';

@Injectable({
	providedIn: 'root',
})
export class ProspectusExternalClickApiService {
	constructor(
		private _http: HttpClient,
		private _customCookieSrv: CustomCookieService,
		private _apiSrv: EnvironmentApiService,
	) {}

	getIdByProduct(product: Product): ProductId {
		const products: Product[] = [ProductEnum.Undergraduate, ProductEnum.Postgraduate];

		const productId = products.indexOf(product) + 1;

		return productId as ProductId;
	}

	/**
	 * @param uniId university's ukprn
	 * @param id: either the user's ID or 0 if not logged in
	 * @param type 1 for UG, 2 for PG, 3 for CLE
	 * @returns 1 for success, 0 for failure
	 *
	 * if user is logged in, it will create a lead in the Leads table in the CMS
	 * Lead type will be: Prospectus Click.
	 * Otherwise, it will be logged as a guest click with their IP address saved
	 */
	prospectusExternalClicks(uniId: number, product: Product) {
		if (!uniId) return;

		const productId = this.getIdByProduct(product);
		const userId = this._customCookieSrv.get('user_id');

		const params = {
			uid: uniId,
			id: userId ?? 0,
			type: productId,
		};

		lastValueFrom(
			this._http.post(
				this._apiSrv.PHP_URL + 'Universities/uc_pps_click_tracking.php',
				params,
			),
		)
			.then(console.log)
			.catch(console.error);
	}
}
