import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { ConversionEventsService } from '@uc/web/core';
import { SourceTrackingTypes } from '@uc/web/shared/data-models';
import { ClearingVisitWebsiteBtnService } from './clearing-visit-website-btn.service';

@Component({
	selector: 'uc-clearing-visit-website-btn',
	standalone: true,
	imports: [NgClass],
	templateUrl: './clearing-visit-website-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClearingVisitWebsiteBtnComponent {
	externalUrl = input.required<string | undefined>();
	uniId = input.required<number | null>();
	uniName = input.required<string>();
	source = input.required<string>(); // uni name or course name
	sourceType = input.required<SourceTrackingTypes>();
	cleSearchOrderPosition = input.required<number | null>();
	componentName = input.required<string>();
	buttonText = input('Visit Website');
	customStyles = input('w-auto');
	trackingEnabled = input(true);
	visitWebsiteClick = output<void>();

	constructor(
		private _conversionEventsSrv: ConversionEventsService,
		private _clearingVisitWebsiteBtnSrv: ClearingVisitWebsiteBtnService,
	) {}

	// GTM Event
	onVisitWebsiteClick() {
		this._clearingVisitWebsiteBtnSrv.onClearingVisitWebsiteClick(
			this.uniId(),
			this.uniName(),
			this.componentName(),
			this.cleSearchOrderPosition(),
			this.source(),
			this.sourceType(),
			this.trackingEnabled(),
		);
	}

	// Google Ads Conversion
	onVisitWebsiteConversion() {
		this._conversionEventsSrv.visitWebsite();
	}
}
