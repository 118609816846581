<section class="bg-white divider">
	<div class="flex flex-col">
		<div class="relative overflow-hidden">
			<a
				routerLink="/degree-quiz/quick-quiz"
				(click)="onQuizButtonClick('short')"
				class="block w-full h-full rounded-2xl"
			>
				<uc-engage-card-cta-img backgroundImageClass="bg-cta-quiz" />
			</a>

			<div class="relative pt-6 mx-auto md:py-12 max-w-7xl">
				<div class="lg:px-5 md:ml-auto md:w-1/2 md:pl-8 lg:pl-16">
					<div class="flex items-center gap-3">
						<img
							width="20"
							height="20"
							alt=""
							[ngSrc]="'/assets/img/lightbulb.svg' | cdn"
						/>
						<p class="font-semibold text-gray-500 uppercase">
							FIND THE IDEAL COURSE FOR YOU
						</p>
					</div>
					<p class="flex flex-wrap-reverse mt-2 tracking-tight">
						<a
							routerLink="/degree-quiz/quick-quiz"
							(click)="onQuizButtonClick('short')"
						>
							<span class="text-2xl font-bold md:text-3xl">
								Degree Course Quiz
							</span>
						</a>
					</p>
					<p class="mt-4 mb-8 text-base text-gray-500">
						Find the ideal university course for you in minutes by taking our
						degree matchmaker quiz today.
					</p>
					<div class="flex gap-3 mt-8">
						<a
							routerLink="/degree-quiz/quick-quiz"
							(click)="onQuizButtonClick('short')"
							class="inline-block w-full blue-button md:w-auto"
						>
							Take Quick Quiz
						</a>
						<a
							routerLink="/degree-quiz/full-quiz"
							(click)="onQuizButtonClick('long')"
							class="inline-block w-full secondary-button md:w-auto"
						>
							Take Full Quiz
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
