<!-- Loading Animation -->
@if (loading) {
	<div class="grid gap-10">
		<div [innerHTML]="loadAnimation"></div>
	</div>
}
@if (uni && !loading) {
	<div>
		<div class="pb-10 border-b border-gray-200">
			<h3 class="text-xl font-semibold text-black">{{ uni.name }}</h3>
			<div class="relative w-full h-64 mt-6">
				<img
					[ngSrc]="uni.undergraduate?.cover_image | cdn"
					fill
					[attr.alt]="'Image of ' + uni.name"
					class="object-cover"
				/>
			</div>
		</div>
		<div class="pb-10 mt-10">
			<h3 class="mb-4 text-xl font-semibold text-black">Key Information</h3>
			<div class="grid gap-3">
				@if (uni.stats?.total_students) {
					<div class="flex justify-between">
						<p class="font-medium">Students</p>
						<p class="text-gray-500">
							{{ uni.stats?.total_students | number: '' : 'en-GB' }}
							Students
						</p>
					</div>
				}
				<div class="flex justify-between">
					<p class="font-medium">Rank</p>
					@if (uni.stats && uni.stats.rank_position) {
						<p class="text-gray-500">
							{{
								uni.stats.rating
									? (uni.stats.rank_position | ordinal)
									: 'Varied'
							}}
						</p>
					}
				</div>
				@if (uni.courses_count) {
					<div class="flex justify-between">
						<p class="font-medium">Available Courses</p>
						<p class="text-gray-500">{{ uni.courses_count }} Courses</p>
					</div>
				}
			</div>
		</div>
		<!-- General content -->
		<ng-content />
		<!-- Privacy Policy -->
		<ng-content selector="[privacy-policy]" />
	</div>
}
