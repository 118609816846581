import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CloseModalComponent, ModalService } from '@uc/shared/ui';
import { SecondaryBlueBtnComponent } from '../../buttons/secondary-blue-btn/secondary-blue-btn.component';
import { ExclamationMarkTriangularRoundBgSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-uni-not-allowed-modal',
	standalone: true,
	templateUrl: './uni-not-allowed-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CloseModalComponent,
		SecondaryBlueBtnComponent,
		ExclamationMarkTriangularRoundBgSvgComponent,
	],
})
export class UniNotAllowedModalComponent {
	constructor(private modalSrv: ModalService) {}

	closeModalPanel() {
		this.modalSrv.close();
	}
}
