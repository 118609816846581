<section
	aria-hidden="true"
	aria-label="User not signed in"
	class="relative mx-auto bg-white rounded-lg shadow-lg max-w-max"
>
	<uc-close-modal />
	<div class="flex flex-col items-center justify-center p-6 max-w-[400px]">
		<div class="p-2 rounded-full bg-[#FFFAEB]">
			<div class="w-full h-full p-3 rounded-full bg-[#FEF0C7]">
				<uc-not-logged-in-error-svg />
			</div>
		</div>
		<div class="mt-5 text-center">
			<h3 class="text-lg font-semibold text-gray-900">You are not signed in</h3>
			<p class="text-sm text-gray-500">
				To save courses and universities you must have a Uni Compare account.
				Register or Sign in below.
			</p>
			<div class="flex gap-3 mt-8">
				<uc-secondary-blue-btn
					[button]="true"
					(click)="onLoginClick(); showModalPanel()"
					buttonText="Sign In"
					class="w-full"
					customStyles="w-full"
				/>
				<uc-primary-blue-btn
					(click)="closeModalPanel()"
					buttonText="Register"
					link="/register"
					class="w-full"
					customStyles="w-full"
				/>
			</div>
		</div>
	</div>
</section>
