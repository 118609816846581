<div class="w-full">
	<label for="search" class="sr-only">Search</label>
	<div class="sm:flex sm:items-center">
		<div class="relative w-full">
			<div
				class="absolute inset-y-0 left-0 flex items-center pl-6 pointer-events-none"
			>
				<uc-search-svg customStyles="text-gray-400" />
			</div>
			<input
				[(ngModel)]="searchTerm"
				class="w-full px-4 pl-16 sm:py-3 sm:h-[52px] text-base text-gray-600 placeholder-gray-500 bg-white border !border-gray-300 rounded-lg filter-overlay-search sm:rounded-r-none border-r-0 focus-within:ring-0"
				type="search"
				[placeholder]="placeholder"
				aria-label="Search"
				autocomplete="off"
			/>
		</div>

		<button
			(click)="onSearch()"
			class="flex items-center justify-center w-full gap-4 px-8 py-2 mt-4 font-bold bg-white border-l-2 rounded-md sm:text-lg sm:rounded-l-none sm:py-3 sm: sm:rounded-r-lg sm:mt-0 text-uc-blue-900 border-l-uc-blue-900 hover:bg-gray-100 sm:w-36"
		>
			Search
			<uc-arrow-right-svg customStyles="text-inherit sm:w-5 sm:h-5 sm:ml-2.5" />
		</button>
	</div>
</div>
