@if (article && categoryUrl) {
	<a
		[routerLink]="
			articleUrl
				? ['/', articleUrl[0], articleUrl[1], articleUrl[2]]
				: ['../../', correctCategoryUrl | categoryLink: 'advice', article.seo_url]
		"
		class="block py-6 transition-shadow border rounded-lg shadow-sm px-7 hover:shadow-md"
	>
		<span class="block mb-2 text-sm font-medium capitalize text-uc-blue-600">
			{{ article.category ? article.category : article.cat_name }}
		</span>
		<span class="block mb-1 text-lg font-bold text-gray-900">
			{{ article.title | ucEntities }}
		</span>
		<span class="block text-sm text-gray-500">
			{{ article.date ? (article.date | ucdate) : (article.date_posted | ucdate) }}
			@if (article.reading_time > 0) {
				<span> &middot; {{ article.reading_time }} min read</span>
			}
		</span>
	</a>
}
