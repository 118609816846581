import { newInvariantError, invariant } from '../../utilities/globals/index.js';
import { Observable } from '../../utilities/index.js';
import { validateOperation, createOperation, transformOperation } from '../utils/index.js';
function passthrough(op, forward) {
  return forward ? forward(op) : Observable.of();
}
function toLink(handler) {
  return typeof handler === 'function' ? new ApolloLink(handler) : handler;
}
function isTerminating(link) {
  return link.request.length <= 1;
}
var ApolloLink = function () {
  function ApolloLink(request) {
    if (request) this.request = request;
  }
  ApolloLink.empty = function () {
    return new ApolloLink(function () {
      return Observable.of();
    });
  };
  ApolloLink.from = function (links) {
    if (links.length === 0) return ApolloLink.empty();
    return links.map(toLink).reduce(function (x, y) {
      return x.concat(y);
    });
  };
  ApolloLink.split = function (test, left, right) {
    var leftLink = toLink(left);
    var rightLink = toLink(right || new ApolloLink(passthrough));
    if (isTerminating(leftLink) && isTerminating(rightLink)) {
      return new ApolloLink(function (operation) {
        return test(operation) ? leftLink.request(operation) || Observable.of() : rightLink.request(operation) || Observable.of();
      });
    } else {
      return new ApolloLink(function (operation, forward) {
        return test(operation) ? leftLink.request(operation, forward) || Observable.of() : rightLink.request(operation, forward) || Observable.of();
      });
    }
  };
  ApolloLink.execute = function (link, operation) {
    return link.request(createOperation(operation.context, transformOperation(validateOperation(operation)))) || Observable.of();
  };
  ApolloLink.concat = function (first, second) {
    var firstLink = toLink(first);
    if (isTerminating(firstLink)) {
      globalThis.__DEV__ !== false && invariant.warn(33, firstLink);
      return firstLink;
    }
    var nextLink = toLink(second);
    if (isTerminating(nextLink)) {
      return new ApolloLink(function (operation) {
        return firstLink.request(operation, function (op) {
          return nextLink.request(op) || Observable.of();
        }) || Observable.of();
      });
    } else {
      return new ApolloLink(function (operation, forward) {
        return firstLink.request(operation, function (op) {
          return nextLink.request(op, forward) || Observable.of();
        }) || Observable.of();
      });
    }
  };
  ApolloLink.prototype.split = function (test, left, right) {
    return this.concat(ApolloLink.split(test, left, right || new ApolloLink(passthrough)));
  };
  ApolloLink.prototype.concat = function (next) {
    return ApolloLink.concat(this, next);
  };
  ApolloLink.prototype.request = function (operation, forward) {
    throw newInvariantError(34);
  };
  ApolloLink.prototype.onError = function (error, observer) {
    if (observer && observer.error) {
      observer.error(error);
      return false;
    }
    throw error;
  };
  ApolloLink.prototype.setOnError = function (fn) {
    this.onError = fn;
    return this;
  };
  return ApolloLink;
}();
export { ApolloLink };
