import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Product, ProductEnum } from '@uc/web/shared/data-models';
import { RequestBtnService } from './request-btn.service';
import { ProductHandlerService, RequestInfoGTMService } from '@uc/web/core';

@Component({
	selector: 'uc-request-btn',
	standalone: true,
	templateUrl: './request-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, RouterModule],
})
export class RequestBtnComponent {
	@Input({ required: true }) product: Product | null = null;
	@Input() params!: string | number | undefined;
	@Input() componentName!: string;
	@Input() customStyles = 'w-full';
	@Input() buttonText = 'Request info';
	@Input() courseName = '';
	@Input() ugSearchOrderPosition!: number | null;

	constructor(
		private _requestInfoGTMSrv: RequestInfoGTMService,
		private _productHandlerSrv: ProductHandlerService,
		private _requestBtnSrv: RequestBtnService,
	) {}

	onRequestInfoClick() {
		this._requestInfoGTMSrv.requestInfoClick(
			this.componentName,
			this.product === ProductEnum.Undergraduate || !this.product
				? this.ugSearchOrderPosition
				: null,
		);
		this._productHandlerSrv.setProduct(this.product);
		this._requestBtnSrv.setCourseName(this.courseName);
	}
}
