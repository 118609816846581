import { createAction, props } from '@ngrx/store';
import { RecommendedUnisCollection } from '@uc/web/shared/data-models';

export const fetchRecommendedUniversities = createAction(
	'[StateRecommendedUniversities/API] Fetch Recommended Universities',
);

export const updateRecommendedUniversities = createAction(
	'[StateRecommendedUniversities] Update Recommended Universities',
	props<{ universities: RecommendedUnisCollection }>(),
);

export const updateSavedInStore = createAction(
	'[StateRecommendedUniversities] Update Save Property',
	props<{ uniId: number }>(),
);

export const loadStateRecommendedUniversitiesFailure = createAction(
	'[StateRecommendedUniversities] Load StateRecommendedUniversities Failure',
	props<{ error: any }>(),
);
