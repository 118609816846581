import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class RequestBtnService {
	private _courseName = new BehaviorSubject<string | null>(null);
	courseName$ = this._courseName.asObservable();

	setCourseName(courseName: string | null): void {
		this._courseName.next(courseName);
	}
}
