import { CtaCard } from './clearing-search.models';

export const getContent = (year: number): CtaCard[] => {
	return [
		{
			title: 'Search University Clearing Courses',
			desc: `Start the search for your ideal Clearing ${year} course with our Clearing Course Search. Filter from thousands of available courses.`,
			image: '/assets/img/clearing_courses_cta.png',
			link: '/courses/clearing',
			external: false,
		},
		{
			title: 'Search Universities in Clearing',
			desc: `Take the stress out of Clearing ${year} with our Uni search tool. Filter based on rankings, reviews, and course availability.`,
			image: '/assets/img/clearing_universities_cta.png',
			link: '/universities/clearing',
			external: false,
		},
	];
};
