<div class="-mb-px space-x-10 sm:block">
	<button
		type="button"
		[ngClass]="{ 'text-white hover:text-white': !saved }"
		(click)="onSave()"
		class="flex items-center gap-2 px-5 py-3 text-sm font-medium border-2 rounded-md cursor-pointer group bookmark secondary-button only-tab-focus"
	>
		@if (!saved) {
			<uc-heart-svg
				customStyles="!fill-white stroke-uc-blue-900 group-hover:stroke-uc-blue-950 stroke-2"
			/>
		}
		@if (saved) {
			<uc-heart-svg customStyles="!fill-uc-red-600" />
		}
		@if (delta === 1) {
			<span class="font-bold"> {{ saved ? 'Unsave' : 'Save' }} University </span>
		}
		@if (delta === 2) {
			<span class="font-bold"> {{ saved ? 'Unsave' : 'Save' }} Course </span>
		}
	</button>
</div>
