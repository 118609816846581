import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import {
	ConversionEventsService,
	ProspectusGTMService,
	ProductHandlerService,
} from '@uc/web/core';
import { Product } from '@uc/web/shared/data-models';
import { EngageCardCtaImgComponent } from '../engage-card-cta/components/engage-card-cta-img/engage-card-cta-img.component';
import { DOCUMENT, NgOptimizedImage } from '@angular/common';
import { ProspectusBtnComponent } from '../../buttons/prospectus-btn/prospectus-btn.component';
import { CdnPathPipe } from '@uc/utilities';
import { Router } from '@angular/router';

@Component({
	selector: 'uc-engage-card-prospectus-cta',
	standalone: true,
	imports: [
		EngageCardCtaImgComponent,
		NgOptimizedImage,
		ProspectusBtnComponent,
		CdnPathPipe,
	],
	templateUrl: './engage-card-prospectus-cta.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngageCardProspectusCtaComponent {
	@Input({ required: true }) mainTitle!: string;
	@Input({ required: true }) description!: string;
	@Input({ required: true }) subTitle!: string;
	@Input({ required: true }) uniId!: number;
	@Input({ required: true }) externalProspectusUrl!: string;
	@Input({ required: true }) product!: Product;
	@Input({ required: true }) ugSearchOrderPosition!: number | null;
	@Input({ required: true }) privacyPolicyUrl!: string;
	private _window: Window & typeof globalThis;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private _router: Router,
		private _prospectusGTMSrv: ProspectusGTMService,
		private _productHandlerSrv: ProductHandlerService,
		private _conversionEventsSrv: ConversionEventsService,
	) {
		this._window = this._document.defaultView as Window & typeof globalThis;
	}

	onProspectusClick() {
		this._prospectusGTMSrv.getProspectusClick(
			'Get Prospectus CTA',
			this.ugSearchOrderPosition,
		);

		if (this.externalProspectusUrl) {
			this._handleExternalLink();
		} else {
			this._handleInternalLink();
		}
	}

	private _handleExternalLink() {
		this._productHandlerSrv.setProduct(this.product);
		this._window.open(this.externalProspectusUrl);
	}

	private _handleInternalLink() {
		this._productHandlerSrv.setProduct(this.product);
		this._conversionEventsSrv.prospectusOrder(1);
		this._router.navigate(['/order'], {
			queryParams: { uni: this.uniId },
		});
	}
}
