import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SelectedUniPrivacyPolicy } from './privacy-policies-modal.models';

@Injectable({ providedIn: 'root' })
export class PrivacyPoliciesModalService {
	private _selectedUnis = new BehaviorSubject<SelectedUniPrivacyPolicy[]>([]);
	private _isInAppBrowser = false;
	selectedUnis$ = this._selectedUnis.asObservable();

	setUniWithPrivacyPolicy(uni: SelectedUniPrivacyPolicy[]) {
		this._selectedUnis.next(uni);
	}

	setIsInAppBrowser(isInAppBrowser: boolean) {
		this._isInAppBrowser = isInAppBrowser;
	}

	getIsInAppBrowser() {
		return this._isInAppBrowser;
	}
}
