import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookEvent, BookEventGTMService } from '@uc/web/core';
import { Product } from '@uc/web/shared/data-models';
import { BookEventBtnApiService } from './book-event-btn-api.service';
import { CommonModule } from '@angular/common';
import { CustomCookieService } from '@uc/shared/authentication';

@Component({
	selector: 'uc-book-event-btn',
	standalone: true,
	templateUrl: './book-event-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class BookEventBtnComponent {
	@Input({ required: true }) uniId!: number;
	@Input({ required: true }) product!: Product;
	@Input({ required: true }) link!: string;
	@Input({ required: true }) componentName = '';
	@Input({ required: true }) uniName!: string;
	@Input() eventType: string | null | undefined = null;
	@Input() date: string | null | undefined = null;
	@Input() customStyles = 'w-auto';
	@Input() buttonText = 'Book Event';
	@Input() eventId!: number;
	@Input({ required: true }) ugSearchOrderPosition!: number | null;
	private _userId = this._customCookieSrv.get('user_id');

	constructor(
		private _customCookieSrv: CustomCookieService,
		private _bookEventGTMSrv: BookEventGTMService,
		private _bookEventBtnApiSrv: BookEventBtnApiService,
	) {}

	onBookEventClick() {
		const args: BookEvent = {
			uniId: this.uniId,
			uniName: this.uniName,
			componentName: this.componentName,
			eventType: this.eventType,
			date: this.date,
			isUser: this._userId ? true : false,
			ugSearchOrderPosition: this.ugSearchOrderPosition,
		};

		this._bookEventGTMSrv.bookEventClick(args);

		// track each interaction as guest (IP address)
		const eventId = this.eventId ? this.eventId : 0;
		this._bookEventBtnApiSrv.trackEventClicks(this.uniId, eventId, this.product);
	}
}
