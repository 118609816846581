import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LeadsUniDetailsImageComponent } from './leads-uni-details-image/leads-uni-details-image.component';
import { LoadingAnimationService } from '@uc/shared/ui';

@Component({
	selector: 'uc-leads-university-details-v1',
	standalone: true,
	templateUrl: './leads-university-details-v1.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, LeadsUniDetailsImageComponent],
})
export class LeadsUniversityDetailsV1Component {
	@Input({ required: true }) coverImage!: string;
	@Input({ required: true }) uniName!: string;
	@Input({ required: true }) uniLogo!: string;
	@Input({ required: true }) recommendedPercentage!: number;
	@Input() courseCount!: number;
	@Input() loading = false;
	@Input() noSelectInfo!: string;
	@Input() hidePolicy = false;
	@Input() policyText = '';
	@Input() displayGoogleMap!: boolean;
	@Input() displayBottomBorder = true;
	@Input() isClearingCourses = false;

	loadAnimation = this.loadSrv.createUniCard();

	constructor(private loadSrv: LoadingAnimationService) {}
}
