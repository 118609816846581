import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Apollo } from 'apollo-angular';

import { GET_UNIVERSITY_EVENTS } from './queries.graphql';
import { UniversityEventResponse } from '@uc/web/shared/data-models';

@Injectable({
	providedIn: 'root',
})
export class UniversityEventsAPIService {
	constructor(private apollo: Apollo) {}

	getUniversityEvents(universityUrl: string) {
		return this.apollo
			.query<UniversityEventResponse>({
				query: GET_UNIVERSITY_EVENTS,
				variables: { universityUrl },
			})
			.pipe(map((universityEvents) => universityEvents.data));
	}
}
