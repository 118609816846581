import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
	inject,
} from '@angular/core';
import { FormArray, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArrowDownSvgComponent } from '@uc/shared/svg';
import {
	CustomSelectComponent,
	InputFieldComponent,
	PostcodeInputComponent,
} from '@uc/shared/ui';
import { SelectOption } from '@uc/web/shared/data-models';
import { Subscription } from 'rxjs';

@Component({
	selector: 'uc-address-details',
	standalone: true,
	templateUrl: './address-details.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		PostcodeInputComponent,
		InputFieldComponent,
		FormsModule,
		ReactiveFormsModule,
		CustomSelectComponent,
		ArrowDownSvgComponent,
	],
})
export class AddressDetailsComponent implements OnInit, OnDestroy {
	@Input() parentForm!: FormGroup;
	@Input() formSubmitted!: boolean;
	@Input() regions!: SelectOption[];
	@Input() countries!: SelectOption[];
	@Input() UKResident!: boolean;
	@Input() showTitle = true;
	@Input() hideResidencySwitch = false;

	get form(): FormGroup {
		return this.parentForm.get('addressDetailsForm') as FormGroup;
	}

	get region() {
		return this.form.get('region') as FormArray;
	}

	get postcode() {
		return this.form.get('postcode') as FormArray;
	}

	private _formFieldSub!: Subscription | undefined;

	private _cd = inject(ChangeDetectorRef);

	ngOnInit() {
		this._formFieldSub = this.parentForm
			.get('addressDetailsForm')
			?.valueChanges.subscribe(() => {
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this._formFieldSub?.unsubscribe();
	}

	onClickNotUk(event: boolean): void {
		this.UKResident = event;
		this.parentForm.patchValue({
			addressDetailsForm: {
				country: '',
			},
		});
	}

	onClickFromUk(): void {
		this.UKResident = true;
		this.parentForm.patchValue({
			addressDetailsForm: {
				country: { id: 89, name: 'United Kingdom' },
			},
		});
	}
}
