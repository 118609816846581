import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { FilterDesktopSvgComponent, FilterMobileSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-show-filter-button',
	standalone: true,
	templateUrl: './show-filter-button.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, FilterDesktopSvgComponent, FilterMobileSvgComponent],
})
export class ShowFilterButtonComponent {
	length = input<number>(0);
	customWidth = input<string>('w-auto');
	toggleFilter = output<boolean>();
}
