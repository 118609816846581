import gql from 'graphql-tag';

export const GET_UNIVERSITY_EVENTS = gql`
	query getUniversityEvents($universityUrl: String) {
		universityEvents(university: $universityUrl) {
			id
			description
			date
			type
			external_url
			subtitle
			is_recommended
			campus
			product
		}
	}
`;
