<form [formGroup]="parentForm">
	<div formGroupName="applicationDetailsForm">
		<div class="flex justify-between gap-8">
			@if (title) {
				<h2 class="mb-6 text-xl font-semibold whitespace-nowrap">
					Application Details
				</h2>
			}
			@if (showTooltip) {
				<div class="relative hidden sm:block tooltip-hover">
					<p class="pb-4 text-sm text-right cursor-pointer blue-link dropbtn">
						Why are we asking these questions?
					</p>
					<div id="registerTooltip" class="tooltip">
						<div
							class="absolute z-20 flex items-center py-2 bg-white border border-gray-200 rounded-lg sm:-right-28 lg:-left-3 reg-tool-w px-9"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="flex-shrink-0 w-5 h-5 mr-2 text-gray-500"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
									clip-rule="evenodd"
								/>
							</svg>
							<p class="text-sm text-gray-500">
								We ask about these details so we can understand your needs
								and connect you to the right universities and courses.
							</p>
						</div>
					</div>
				</div>
			}
		</div>

		<div class="grid gap-6 lg:grid-cols-2">
			<!-- degree options -->
			<uc-custom-select
				[data]="degrees.options"
				[formControlName]="degrees.formControlName"
				[controlName]="degrees.controlName"
				labelName="Qualification"
				placeholder="Qualification*"
				errorMsg="Please select a degree."
				[isArrayOfObjects]="false"
				key="degree"
				[disableTick]="false"
				[formSubmitted]="formSubmitted"
			/>

			<!-- start year -->
			<uc-custom-select
				[data]="years.options"
				[formControlName]="years.formControlName"
				[controlName]="years.controlName"
				labelName="Start Year"
				placeholder="Start Year*"
				errorMsg="Please select a year."
				[isArrayOfObjects]="false"
				key="year"
				[disableTick]="false"
				[formSubmitted]="formSubmitted"
			/>
		</div>

		@if (subjects?.options) {
			<div class="mt-6">
				<div>
					<uc-custom-select
						[data]="subjects.options"
						[formControlName]="subjects.formControlName"
						[controlName]="subjects.controlName"
						labelName="Select Subject"
						placeholder="Select Subject*"
						errorMsg="Please select a subject."
						[isArrayOfObjects]="subjects.isArrayOfObjects"
						[key]="subjects.key"
						[disableTick]="false"
						[formSubmitted]="formSubmitted"
					/>
				</div>
			</div>
		}
	</div>
</form>
