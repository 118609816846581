<div class="sm:border sm:rounded-lg sm:py-6 sm:px-5">
	<div
		ucImpression
		[count]="true"
		(onImpression)="onUniImpression($event)"
		class="sm:flex sm:items-center"
	>
		<!-- Mobile: Cover image -->
		<div class="relative w-full h-40 rounded-lg sm:hidden">
			<div class="absolute z-10 sm:hidden top-4 right-4">
				<uc-bookmark
					[saved]="saved()"
					[id]="uniId()"
					[delta]="1"
					[bookmarkHeartIcon]="true"
					componentName="University Listing"
					[ugSearchOrderPosition]="ugSearchOrderPosition()"
					[cleSearchOrderPosition]="null"
					[uniName]="uniName()"
				/>
			</div>
			<img
				[ngSrc]="uniCover() | cdn"
				fill
				[attr.alt]="'Cover image of ' + uniName()"
				class="object-cover w-full h-full rounded-xl"
			/>
		</div>

		<!-- Desktop: Logo -->
		<a
			(click)="onUniProfileClick()"
			[routerLink]="internalUniUrl"
			class="hidden z-[-1] pr-5 sm:inline-block"
			data-test-id="profile-link-desktop"
		>
			<span class="relative block w-16 h-16">
				<img
					class="object-contain border rounded-lg"
					[ngSrc]="uniLogo() | cdn"
					fill
					[attr.alt]="'Logo of ' + uniName()"
				/>
			</span>
		</a>

		<div class="flex items-center justify-between w-full pt-5 sm:pt-0">
			<div>
				<h2 class="text-xl font-bold text-black">
					<a
						(click)="onUniProfileClick()"
						[routerLink]="internalUniUrl"
						data-test-id="profile-link-mobile"
					>
						{{ uniName() }}</a
					>
				</h2>
				@if (recommended()) {
					<p class="mr-1 text-sm text-gray-500" data-test-id="recommended-info">
						{{ recommended() }}% Recommended
					</p>
				} @else {
					<uc-write-review-btn
						[universityId]="uniId()"
						[universityName]="uniName()"
						[universityUrl]="uniSlug()"
						buttonText="Leave a review"
					/>
				}
			</div>

			<!-- Mobile -->
			@if (isFeatured) {
				<uc-visit-website-btn
					customStyles="w-full sm:hidden py-2.5"
					[externalUrl]="externalUrl()"
					product="undergraduate"
					componentName="University Listing"
					[uniId]="uniId()"
					buttonText="Website"
					sourceType="uni"
					[source]="uniName()"
					[ugSearchOrderPosition]="ugSearchOrderPosition()"
					[uniName]="uniName()"
				/>
			}
		</div>

		<!-- Desktop -->
		<div class="hidden gap-4 ml-2 sm:flex sm:items-center">
			@if (displaySeeCoursesBtn()) {
				<uc-secondary-blue-btn
					(buttonClicked)="onViewMoreCourses()"
					[link]="
						product() ? '/courses/' + product() : '/courses/undergraduate'
					"
					[id]="uniSlug()"
					[queryParams]="queryParams"
					[buttonText]="
						source() === 'universities' ? 'See Courses' : 'See More Courses'
					"
					data-test-id="see-courses-desktop"
				/>
			}

			<uc-bookmark
				[saved]="saved()"
				[id]="uniId()"
				[delta]="1"
				[bookmarkHeartIcon]="true"
				componentName="University Listing"
				[ugSearchOrderPosition]="ugSearchOrderPosition()"
				[cleSearchOrderPosition]="null"
				[uniName]="uniName()"
			/>
		</div>
	</div>

	<!-- Inject dynamic content: Courses -->
	<div class="flex flex-col">
		<div class="order-2 sm:order-1">
			<ng-content select="[courses]"></ng-content>
		</div>
		<div class="order-1 sm:order-2">
			<!-- CTA -->
			@if (isFeatured) {
				<uc-cta-university-btns
					[uniId]="uniId()"
					[uniSlug]="uniSlug()"
					[product]="product()"
					[externalUrl]="externalUrl()"
					[externalUgProspectusUrl]="externalUgProspectusUrl() ?? ''"
					[externalPgProspectusUrl]="externalPgProspectusUrl() ?? ''"
					[isFeaturedUndergraduate]="
						cvSrv.checkFeature(
							clientFeatures(),
							clientFeatureOptions.Undergraduate
						)
					"
					[isFeaturedPostgraduate]="
						cvSrv.checkFeature(
							clientFeatures(),
							clientFeatureOptions.Postgraduate
						)
					"
					[privacyPolicyUrl]="privacyPolicyUrl()"
					[uniName]="uniName()"
					[ugSearchOrderPosition]="ugSearchOrderPosition()"
				/>
			}
			<!-- End CTA -->
		</div>
	</div>
	<!-- Mobile -->
	@if (displaySeeCoursesBtn()) {
		<uc-secondary-blue-btn
			customStyles="w-full mt-4 btn-px-overwrite sm:hidden"
			(buttonClicked)="onViewMoreCourses()"
			[link]="product() ? '/courses/' + product() : '/courses/undergraduate'"
			[id]="uniSlug()"
			[queryParams]="queryParams"
			[buttonText]="
				source() === 'universities' ? 'See Courses' : 'See More Courses'
			"
			data-test-id="see-courses-mobile"
		/>
	}
</div>
