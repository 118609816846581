import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	FormOptionsState,
	_STATE_FORM_OPTIONS_FEATURE_KEY,
} from './form-options.reducer';

const getFormOptionsState = createFeatureSelector<FormOptionsState>(
	_STATE_FORM_OPTIONS_FEATURE_KEY,
);

export const getCountryFormOptions = createSelector(
	getFormOptionsState,
	(state: FormOptionsState) => state.countries,
);

export const getSubjectFormOptions = createSelector(
	getFormOptionsState,
	(state: FormOptionsState) => state.subjects,
);
