import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LevelSearchBar } from './level-search-bar.models';

@Injectable({ providedIn: 'root' })
export class LevelSearchBarService {
	private _searchValue$ = new Subject<LevelSearchBar>();
	searchValue$ = this._searchValue$.asObservable();

	setSearchValue(searchTerm: string, studyLevel: string) {
		this._searchValue$.next({ searchTerm, studyLevel });
	}
}
