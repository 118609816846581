@if (prevPath$ | async; as prevPath) {
	@if (showDownloadCta) {
		<div class="flex flex-col items-center gap-3 sm:flex-row md:flex-col lg:flex-row">
			<a [routerLink]="prevPath.path" class="block w-full blue-button">
				Download Our App
			</a>
			<a routerLink="/" class="block w-full secondary-button">
				Visit Our Website
			</a>
		</div>
	}
	@if (!showDownloadCta) {
		<a
			[routerLink]="prevPath.path"
			[queryParams]="prevPath.params"
			class="block w-full secondary-button"
		>
			Back to {{ previousPageName }}
		</a>
	}
} @else {
	<a routerLink="/" class="block w-full secondary-button"> Back to Home </a>
}
