@if (!button) {
	<a
		(click)="buttonClicked.emit()"
		class="inline-flex items-center justify-center secondary-button focus:outline-none whitespace-nowrap"
		[routerLink]="link"
		[queryParams]="queryParams"
		[ngClass]="customStyles"
	>
		{{ buttonText }}
	</a>
}
@if (button) {
	<button
		(click)="buttonClicked.emit()"
		type="button"
		class="inline-flex items-center justify-center secondary-button focus:outline-none whitespace-nowrap"
		[ngClass]="customStyles"
	>
		{{ buttonText }}
	</button>
}
