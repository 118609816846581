import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CourseGTMService } from '@uc/web/core';
import { Product } from '@uc/web/shared/data-models';
import { CommonModule } from '@angular/common';
import { CustomCookieService } from '@uc/shared/authentication';

@Component({
	selector: 'uc-visit-website-keyword-btn',
	standalone: true,
	templateUrl: './visit-website-keyword-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class VisitWebsiteKeywordBtnComponent {
	@Input() externalUrl!: string | undefined;
	@Input() buttonText = 'Visit Website';
	@Input() customStyles = 'w-auto';
	@Input() product: Product | '' = '';
	@Input() componentName!: string;
	@Input() uniId!: number | null;
	@Input() uniName!: string;

	userAuthenticated = this.customCookieSrv.get('user_id') ? true : false;

	constructor(
		private courseGTMSrv: CourseGTMService,
		private customCookieSrv: CustomCookieService,
	) {}

	onKeywordClick() {
		if (this.uniId) {
			this.courseGTMSrv.keywordClick(
				this.componentName,
				this.uniId,
				this.uniName,
				this.userAuthenticated,
				this.product,
			);
		}
	}
}
