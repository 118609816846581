<section class="bg-white divider">
	<div class="flex flex-col">
		<div class="relative overflow-hidden">
			<a
				(click)="onRegisterInterestClick()"
				routerLink="/register-interest"
				[queryParams]="{ uni: uniId, source: sourceType }"
			>
				<uc-engage-card-cta-img
					backgroundImage="/assets/img/register_interest_cta.png"
				></uc-engage-card-cta-img>
			</a>
			<div class="relative pt-6 mx-auto md:py-12 max-w-7xl">
				<div class="lg:px-5 md:ml-auto md:w-1/2 md:pl-8 lg:pl-16">
					<div class="flex items-center gap-3">
						<img
							width="20"
							height="20"
							alt=""
							[ngSrc]="'/assets/img/message.svg' | cdn"
						/>
						<p class="font-semibold text-gray-500 uppercase">
							Message the University
						</p>
					</div>
					<p class="flex flex-wrap-reverse mt-2 tracking-tight">
						<a
							(click)="onRegisterInterestClick()"
							routerLink="/register-interest"
							[queryParams]="{ uni: uniId, source: sourceType }"
							><span class="text-2xl font-bold md:text-3xl">
								Register Your Interest Now
							</span>
						</a>
					</p>
					<p class="mt-4 text-base text-gray-500">
						Has a university or course caught your eye? Be first in line by
						registering your interest in a Clearing
						{{ currentClearingYear }} course today.
					</p>
					<uc-register-interest-btn
						customStyles="w-full md:max-w-max mt-8"
						[uniId]="uniId"
						[sourceType]="sourceType"
						componentName="Register Interest CTA"
						[cleSearchOrderPosition]="cleSearchOrderPosition"
					/>
				</div>
			</div>
		</div>
	</div>
</section>
