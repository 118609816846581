import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Product } from '@uc/web/shared/data-models';
import { EnvironmentApiService } from '@uc/utilities';
import { ProductHandlerService } from '@uc/web/core';

@Injectable({
	providedIn: 'root',
})
export class BookEventBtnApiService {
	constructor(
		private _http: HttpClient,
		private _productHandlerSrv: ProductHandlerService,
		private _apiSrv: EnvironmentApiService,
	) {}

	/**
	 * @param uniId universities ukprn
	 * @param eventId event's unique ID
	 * @param type 1 for UG, 2 for PG
	 * @returns 1 for success, 0 for failure
	 * @param id: we only track guest clicks, so this is always 0
	 * if you want to track logged in user in the future, send userID here
	 */
	trackEventClicks(uniId: number, eventId: number, product: Product) {
		const productId = this._productHandlerSrv.getIdByProduct(product);

		const params = {
			uid: uniId,
			id: 0,
			event_id: eventId,
			type: productId,
		};

		lastValueFrom(
			this._http.post(
				this._apiSrv.PHP_URL + 'OpenDays/uc_book_event_tracking.php',
				params,
			),
		)
			.then(console.log)
			.catch(console.error);
	}
}
