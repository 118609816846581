@if (backgroundImageClass) {
	<span
		class="z-40 block h-56 rounded-2xl md:absolute md:left-0 md:h-full md:w-1/2"
		[ngClass]="backgroundImageClass"
	></span>
}
@if (backgroundImage) {
	<span
		class="z-40 block h-56 bg-center bg-no-repeat bg-cover rounded-2xl md:absolute md:left-0 md:h-full md:w-1/2"
		[ngStyle]="{
			'background-image': 'url(' + (backgroundImage | cdn) + ')',
		}"
		[ngClass]="{ 'border border-gray-200': border }"
	></span>
}
