import { createAction, props } from '@ngrx/store';
import { ClientDetails, StudentDetails } from '@uc/web/shared/data-models';

export const fetchStudentDetails = createAction(
	'[UserDetails/API] Fetch Student Details',
);

export const fetchClientDetails = createAction('[UserDetails/API] Fetch Client Details');

export const updateStudentDetails = createAction(
	'[UserDetails] Update Student Details',
	props<{ student: StudentDetails | null }>(),
);

export const updateClientDetails = createAction(
	'[UserDetails] Update Client Details',
	props<{ client: ClientDetails | null }>(),
);

export const resetStudentDetails = createAction(
	'[UserDetails] Reset resetStudentDetails Details',
);

export const resetClientDetails = createAction('[UserDetails] Reset Client Details');

export const loadUserDetailsError = createAction(
	'[UserDetails] Load User Details Error',
	props<{ error: any }>(),
);
