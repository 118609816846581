import { Action, createReducer, on } from '@ngrx/store';
import * as fromUniversityEventsActions from './university-events.actions';
import { UniversityEventResponse } from '@uc/web/shared/data-models';

export const _STATE_UNIVERSITY_EVENTS = 'stateuniversityEvents';

export interface UniversityEventsState {
	allEvents: UniversityEventResponse;
	allEventsUniSlug: string;
	isLoading: boolean;
	error: any;
}

export const initialUniversityEventsState: UniversityEventsState = {
	allEvents: {} as UniversityEventResponse,
	allEventsUniSlug: '',
	isLoading: false,
	error: '',
};

const reducer = createReducer(
	initialUniversityEventsState,
	on(fromUniversityEventsActions.fetchAllEvents, (state) => ({
		...state,
		isLoading: true,
		error: '',
	})),
	on(fromUniversityEventsActions.updateAllEvents, (state, { allEvents }) => ({
		...state,
		allEvents,
		isLoading: false,
		error: '',
	})),
	on(fromUniversityEventsActions.updateAllEventsUniSlug, (state, allEventsUniSlug) => ({
		...state,
		allEventsUniSlug: allEventsUniSlug.uniSlug,
	})),
	on(fromUniversityEventsActions.updateIsLoading, (state) => ({
		...state,
		isLoading: false,
	})),
	on(fromUniversityEventsActions.loadUniversityEventsError, (state, { error }) => ({
		...state,
		isLoading: false,
		error,
	})),
);

export function universityEventsReducer(
	state: UniversityEventsState | undefined,
	action: Action,
) {
	return reducer(state, action);
}
