import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	Environment,
	EnvironmentApiService,
	isClearingKeywordsEnabled,
} from '@uc/utilities';
import { StatementKeyword } from './models/personal-statement-keywords.models';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PersonalStatementKeywordsApiService {
	private _clearingEnabled = false;

	constructor(
		@Inject('environment') environment: Environment,
		private http: HttpClient,
		private _apiSrv: EnvironmentApiService,
	) {
		this._clearingEnabled = environment.clearingEnabled;
	}

	getKeywords(slug: string) {
		if (this._clearingEnabled && isClearingKeywordsEnabled()) {
			return this.getClearingPersonalStatementKeywords(slug);
		} else {
			return this.getPersonalStatementKeywords(slug);
		}
	}

	getPersonalStatementKeywords(slug: string) {
		if (!slug) return of([]);

		return this.http.get<StatementKeyword[]>(
			`${this._apiSrv.API_URL}personal-statements/${slug}/keywords`,
		);
	}

	getClearingPersonalStatementKeywords(slug: string) {
		if (!slug) return of([]);

		return this.http.get<StatementKeyword[]>(
			`${this._apiSrv.API_URL}clearing/personal-statements/${slug}/keywords`,
		);
	}
}
