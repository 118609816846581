import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SourceTrackingTypes } from '@uc/web/shared/data-models';
import { ConversionEventsService } from '@uc/web/core';
import { EngageCardCtaImgComponent } from '../engage-card-cta/components/engage-card-cta-img/engage-card-cta-img.component';
import { CdnPathPipe, ClearingService } from '@uc/utilities';
import { RouterModule } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';
import { RegisterInterestBtnComponent } from '../../buttons/register-interest-btn/register-interest-btn.component';

@Component({
	selector: 'uc-engage-card-register-interest-cta',
	standalone: true,
	templateUrl: './engage-card-register-interest-cta.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		CdnPathPipe,
		RegisterInterestBtnComponent,
		EngageCardCtaImgComponent,
		RouterModule,
		NgOptimizedImage,
	],
})
export class EngageCardRegisterInterestCtaComponent {
	@Input({ required: true }) sourceType!: SourceTrackingTypes;
	@Input({ required: true }) cleSearchOrderPosition!: number | null;
	@Input() uniId!: number;

	currentClearingYear = this._clearingSrv.getYear();

	constructor(
		private _clearingSrv: ClearingService,
		private _conversionEventsSrv: ConversionEventsService,
	) {}

	onRegisterInterestClick(): void {
		this._conversionEventsSrv.clickRegisterInterest();
	}
}
