import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	ClientsDropdownListState,
	_STATE_CLIENTS_DROPDOWN_LIST_FEATURE_KEY,
} from './clients-dropdown-list.reducer';

export const getStateClientsDropdownListState =
	createFeatureSelector<ClientsDropdownListState>(
		_STATE_CLIENTS_DROPDOWN_LIST_FEATURE_KEY,
	);

export const getStateClientsDropdownList = createSelector(
	getStateClientsDropdownListState,
	(state: ClientsDropdownListState) => state.universities,
);
