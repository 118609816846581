import { Injectable, makeStateKey } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PageData } from '../models/page-content.models';
import { TransferStateService } from '@uc/utilities';
import { PagesApiService } from '../page-content.service';
import { fetchPageData, loadPageDataError, updatePageData } from './page-content.actions';
import { catchError, distinctUntilChanged, map, of, switchMap } from 'rxjs';

const TS_PAGE_DATA = makeStateKey<PageData>('ts-page-data');

@Injectable()
export class StatePageDataEffects {
	constructor(
		private readonly _actions$: Actions,
		private _tsSrv: TransferStateService,
		private _pagesApiSrv: PagesApiService,
	) {}

	fetchPageData = createEffect(() =>
		this._actions$.pipe(
			ofType(fetchPageData),
			distinctUntilChanged(),
			switchMap((page) => {
				return this._tsSrv
					.getData<PageData>(
						TS_PAGE_DATA,
						this._pagesApiSrv.getPageContent(page.url),
					)
					.pipe(
						map((response) => updatePageData({ pageData: response })),
						catchError((error) => of(loadPageDataError(error))),
					);
			}),
		),
	);
}
