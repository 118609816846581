import { Action, createReducer, on } from '@ngrx/store';
import { FormSubjectOption, SelectOption } from '@uc/web/shared/data-models';
import {
	fetchCountryFormOptions,
	fetchSubjectFormOptions,
	loadFormOptionsError,
	updateCountryFormOptions,
	updateSubjectFormOptions,
} from './form-options.actions';

export const _STATE_FORM_OPTIONS_FEATURE_KEY = 'stateFormOptions';

export interface FormOptionsState {
	loading: boolean;
	countries: SelectOption[];
	subjects: FormSubjectOption[];
	error: any;
}

const formOptionsInitialState: FormOptionsState = {
	loading: false,
	countries: [],
	subjects: [],
	error: null,
};

const reducer = createReducer(
	formOptionsInitialState,
	on(loadFormOptionsError, (state, { error }) => ({
		...state,
		error,
	})),
	on(fetchCountryFormOptions, fetchSubjectFormOptions, (state) => ({
		...state,
		error: null,
		loading: true,
	})),
	on(updateCountryFormOptions, (state, { countries }) => ({
		...state,
		countries,
		loading: false,
	})),
	on(updateSubjectFormOptions, (state, { subjects }) => ({
		...state,
		subjects,
		loading: false,
	})),
);

export function formOptionsReducer(state: FormOptionsState | undefined, action: Action) {
	return reducer(state, action);
}
