import { Injectable } from '@angular/core';
import { PromoSlot } from '@uc/web/shared/data-models';
import { Apollo } from 'apollo-angular';
import { catchError, EMPTY, map } from 'rxjs';
import { GET_CLEARING_PROMO_SLOTS, GET_PROMO_SLOTS } from './queries.graphql';
import { InAppBrowserService } from '@uc/web/core';

@Injectable({
	providedIn: 'root',
})
export class PromoSlotsApiService {
	constructor(
		private _apollo: Apollo,
		private _inAppBrowserSrv: InAppBrowserService,
	) {}

	getDefaultPromoSlots() {
		const inAppPlatform = this._inAppBrowserSrv.getPlatform();
		const platform = inAppPlatform ? inAppPlatform.toUpperCase() : 'WEB';

		return this._apollo
			.query<{
				promoSlots: PromoSlot[];
			}>({
				query: GET_PROMO_SLOTS,
				variables: { platform },
			})
			.pipe(
				catchError((error) => {
					console.error(error);
					return EMPTY;
				}),
				map(({ data }) => data.promoSlots),
			);
	}

	getClePromoSlots() {
		return this._apollo
			.query<{
				clearingPromoSlots: PromoSlot[];
			}>({
				query: GET_CLEARING_PROMO_SLOTS,
			})
			.pipe(
				catchError((error) => {
					console.error(error);
					return EMPTY;
				}),
				map(({ data }) => data.clearingPromoSlots),
			);
	}
}
