import { Action, createReducer, on } from '@ngrx/store';
import {
	fetchClientDetails,
	fetchStudentDetails,
	loadUserDetailsError,
	resetClientDetails,
	resetStudentDetails,
	updateClientDetails,
	updateStudentDetails,
} from './user-details.actions';
import { ClientDetails, StudentDetails } from '@uc/web/shared/data-models';

export const _STATE_USER_DETAILS_FEATURE_KEY = 'stateUserDetails';

export interface StateUserDetails {
	student: StudentDetails | null;
	client: ClientDetails | null;
	isLoading: boolean;
	error: any;
}

const initialState: StateUserDetails = {
	student: null,
	client: null,
	isLoading: false,
	error: null,
};

const reducer = createReducer(
	initialState,
	on(fetchStudentDetails, fetchClientDetails, (state) => ({
		...state,
		error: null,
		isLoading: true,
	})),

	on(updateStudentDetails, (state, { student }) => ({
		...state,
		student: student,
		error: null,
		isLoading: false,
	})),

	on(updateClientDetails, (state, { client }) => ({
		...state,
		client: client,
		error: null,
		isLoading: false,
	})),

	on(resetStudentDetails, (state) => ({
		...state,
		student: null,
		isLoading: false,
		error: null,
	})),

	on(resetClientDetails, (state) => ({
		...state,
		client: null,
		isLoading: false,
		error: null,
	})),

	on(loadUserDetailsError, (state, { error }) => ({
		...state,
		error,
		isLoading: false,
	})),
);

export function userDetailsReducer(state: StateUserDetails | undefined, action: Action) {
	return reducer(state, action);
}
