import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
	canDeactivate: () => boolean | Observable<boolean>;
}

export const DestructiveActionModalGuard: CanDeactivateFn<ComponentCanDeactivate> = (
	component: ComponentCanDeactivate,
) => (component.canDeactivate ? component.canDeactivate() : true);
