@if (externalUrl) {
	<a
		(click)="onKeywordClick()"
		[attr.href]="externalUrl"
		target="_blank"
		[ngClass]="customStyles"
		class="inline-block blue-button focus:outline-none whitespace-nowrap"
		rel="sponsored noopener"
		data-test-id="visit-website-keyword-btn"
	>
		{{ buttonText }}
	</a>
}
