import { createAction, props } from '@ngrx/store';
import { FormSubjectOption, SelectOption } from '@uc/web/shared/data-models';

const formOptionsKey = '[Form Options]';
const formOptionsApiKey = '[Form Options API]';

export const loadFormOptionsError = createAction(
	`${formOptionsKey} Load Form Options Error`,
	props<{ error: any }>(),
);

export const fetchCountryFormOptions = createAction(
	`${formOptionsApiKey} Fetch Country Form Options`,
);

export const updateCountryFormOptions = createAction(
	`${formOptionsKey} Update Country Form Options`,
	props<{ countries: SelectOption[] }>(),
);

export const fetchSubjectFormOptions = createAction(
	`${formOptionsApiKey} Fetch Subject Form Options`,
);

export const updateSubjectFormOptions = createAction(
	`${formOptionsKey} Update Subject Form Options`,
	props<{ subjects: FormSubjectOption[] }>(),
);
