<div class="grid grid-cols-2 gap-3 pt-4 sm:grid-cols-3 sm:mt-6 sm:border-t sm:pt-6">
	<uc-prospectus-btn
		customStyles="w-full mb-0 flex-1"
		[params]="uniId"
		[link]="externalUgProspectusUrl"
		[secondaryLinkForModal]="externalPgProspectusUrl"
		componentName="University Listing"
		[product]="product"
		[privacyPolicyUrl]="privacyPolicyUrl"
		[isFeaturedPostgraduate]="isFeaturedPostgraduate"
		[isFeaturedUndergraduate]="isFeaturedUndergraduate"
		[showModal]="true"
		[product]="product"
		[showModal]="true"
		[ugSearchOrderPosition]="ugSearchOrderPosition"
	/>
	<uc-request-btn
		[params]="uniId"
		customStyles="w-full sm:w-full flex-1"
		componentName="University Listing"
		[product]="product"
		[ugSearchOrderPosition]="ugSearchOrderPosition"
	/>
	<uc-visit-website-btn
		class="hidden sm:inline-block"
		customStyles="w-full hidden sm:inline-block flex-1"
		[externalUrl]="externalUrl"
		[product]="product"
		componentName="University Listing"
		[uniId]="uniId"
		buttonText="Visit Website"
		[source]="uniName"
		sourceType="uni"
		[ugSearchOrderPosition]="ugSearchOrderPosition"
		[uniName]="uniName"
	/>
</div>
