<!-- internal -->
@if (!link && !secondaryLinkForModal) {
	@if (!showModal) {
		<a
			data-test-id="type-a"
			(click)="onGetProspectusClick(); setProductForTracking()"
			[ngClass]="customStyles"
			class="inline-flex items-center justify-center mb-4 focus:outline-none sm:mb-0 get-prospectus-btn whitespace-nowrap"
			routerLink="/select"
			[queryParams]="{ uni: params }"
		>
			{{ buttonText }}
		</a>
	}
	@if (showModal) {
		<button
			data-test-id="type-b"
			type="button"
			(click)="navigateToOrderPage()"
			[ngClass]="customStyles"
			class="inline-flex items-center justify-center mb-4 focus:outline-none sm:mb-0 get-prospectus-btn whitespace-nowrap"
		>
			{{ buttonText }}
		</button>
	}
}

<!-- external -->
@if (
	(isUserLoggedIn && (link || secondaryLinkForModal)) ||
	(!isUserLoggedIn && link && secondaryLinkForModal)
) {
	<button
		data-test-id="type-c"
		type="button"
		(click)="showExternalModal()"
		[ngClass]="customStyles"
		class="inline-flex items-center justify-center mb-4 sm:mb-0 get-prospectus-btn focus:outline-none whitespace-nowrap"
	>
		{{ buttonText }}
	</button>
}
@if (
	!isUserLoggedIn &&
	((link && !secondaryLinkForModal) || (!link && secondaryLinkForModal))
) {
	<a
		data-test-id="type-d"
		(click)="trackExternalGuestProspectusClick()"
		[ngClass]="customStyles"
		class="inline-flex items-center justify-center mb-4 sm:mb-0 get-prospectus-btn focus:outline-none whitespace-nowrap"
		target="_blank"
		rel="noopener sponsored"
		[attr.href]="link ? link : secondaryLinkForModal"
	>
		{{ buttonText }}
	</a>
}
