import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentApiService } from '@uc/utilities';
import { GuideSummary, GuideCategory, SingleGuide } from '@uc/web/shared/data-models';

@Injectable({
	providedIn: 'root',
})
export class GuidesApiService {
	constructor(
		private _http: HttpClient,
		private _apiSrv: EnvironmentApiService,
	) {}

	getDegreeGuides() {
		return this.queryCategoryGuides(1);
	}

	getCityGuides() {
		return this.queryCategoryGuides(2);
	}

	getCareerGuides() {
		return this.queryCategoryGuides(3);
	}

	// Returns all guides
	getGuides(): Observable<GuideSummary[]> {
		return this._http.get<GuideSummary[]>(
			this._apiSrv.PHP_URL + 'Articles/guis_get.php',
		);
	}

	// TODO refactor APIs
	queryCategoryGuides(id: number): Observable<GuideCategory> {
		const params = {
			id,
		};
		return this._http.post<GuideCategory>(
			this._apiSrv.PHP_URL + 'Articles/uc_gui_get_popular_by_cat.php',
			params,
		);
	}

	getIndividualGuide(guideUrl: string, catId: number): Observable<SingleGuide> {
		const params = {
			url: guideUrl,
			cid: catId,
		};
		return this._http.post<SingleGuide>(
			this._apiSrv.PHP_URL + 'Articles/uc_gui_get.php',
			params,
		);
	}
}
