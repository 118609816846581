import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import {
	Product,
	FeaturedPostgraduate,
	FeaturedUndergraduate,
	ProductEnum,
} from '@uc/web/shared/data-models';
import { ProspectusGTMService, ConversionEventsService } from '@uc/web/core';
import { ProspectusModalComponent } from '../../modals/prospectus-modal/prospectus-modal.component';
import { ProspectusModalService } from '../../modals/prospectus-modal/prospectus-modal.service';
import { ModalService } from '@uc/shared/ui';
import { ProductHandlerService } from '@uc/web/core';
import { CustomCookieService } from '@uc/shared/authentication';
import { ProspectusExternalClickApiService } from '@uc/web/shared/data-access';

@Component({
	selector: 'uc-prospectus-btn',
	standalone: true,
	templateUrl: './prospectus-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, RouterModule],
})
export class ProspectusBtnComponent {
	@Input({ required: true }) componentName!: string;
	@Input() product: Product | null = null;
	@Input() params!: string | number | undefined;
	@Input() link = '';
	@Input() secondaryLinkForModal = '';
	@Input() buttonText = 'Get Prospectus';
	@Input() customStyles = 'w-full';
	@Input() isFeaturedPostgraduate!: FeaturedPostgraduate;
	@Input() isFeaturedUndergraduate!: FeaturedUndergraduate;
	@Input() showModal = false;
	@Input() privacyPolicyUrl = '';
	@Input() ugSearchOrderPosition!: number | null;

	isUserLoggedIn = this._customCookieSrv.get('user_id') ? true : false;

	constructor(
		private _router: Router,
		private _customCookieSrv: CustomCookieService,
		private _prospectusGTMSrv: ProspectusGTMService,
		private _conversionEventsSrv: ConversionEventsService,
		private _productHandlerSrv: ProductHandlerService,
		private _modalSrv: ModalService,
		private _prospectusModalSrv: ProspectusModalService,
		private _prospectusExternalClickApiSrv: ProspectusExternalClickApiService,
	) {}

	navigateToOrderPage() {
		if (
			this.isFeaturedPostgraduate &&
			this.isFeaturedUndergraduate &&
			!this.product
		) {
			this.onGetProspectusClick();
			this._router.navigate(['/select'], {
				queryParams: { uni: this.params },
			});
		}

		// show PG modal (when product cannot be determined && uni only has PG feature)
		else if (!this.isFeaturedUndergraduate && !this.product) {
			this._prospectusModalSrv.setUniForProspectusOrder(this.params);
			this._prospectusModalSrv.setComponentName(this.componentName);
			this._prospectusModalSrv.setModalType('internal');
			this._modalSrv.setContent(ProspectusModalComponent);
			this._modalSrv.show();
		}

		// redirect to appropriate product order page (e.g. uni only has UG feature || product known)
		else {
			this.onGetProspectusClick();
			const product = this.product ?? ProductEnum.Undergraduate;
			this._productHandlerSrv.setProduct(product);
			this._router.navigate(['/order'], {
				queryParams: { uni: this.params },
			});
		}
	}

	showExternalModal() {
		this._prospectusModalSrv.setPrivacyPolicyUrl(this.privacyPolicyUrl);
		this._prospectusModalSrv.setExternalLinks([
			this.link,
			this.secondaryLinkForModal,
		]);
		this._prospectusModalSrv.setModalType('external');
		this._prospectusModalSrv.setComponentName(this.componentName);
		this._prospectusModalSrv.setUniForProspectusOrder(this.params);
		this._prospectusModalSrv.setUgSearchOrderPosition(this.ugSearchOrderPosition);
		if (this.product) this._productHandlerSrv.setProduct(this.product);
		this._modalSrv.setContent(ProspectusModalComponent);
		this._modalSrv.show();
	}

	onGetProspectusClick() {
		this._prospectusGTMSrv.getProspectusClick(
			this.componentName,
			this.ugSearchOrderPosition,
		);
	}
	onProspectusOrderConversion() {
		this._conversionEventsSrv.prospectusOrder(1);
	}
	setProductForTracking() {
		this._productHandlerSrv.setProduct(this.product);
	}

	// only gets executes if user is not logged in and there's only an UG || PG link
	trackExternalGuestProspectusClick() {
		this.onGetProspectusClick();
		this.onProspectusOrderConversion();
		const uniId = this._prospectusModalSrv.turnUniIdIntoNumber(this.params);
		const product = this.link ? ProductEnum.Undergraduate : ProductEnum.Postgraduate;
		this._prospectusExternalClickApiSrv.prospectusExternalClicks(uniId, product);
	}
}
