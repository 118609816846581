import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeadingComponent } from '@uc/shared/ui';
import {
	ClientFeatureOptions,
	Product,
	UniversityEvent,
} from '@uc/web/shared/data-models';
import { EventCardComponent } from '../event-card/event-card.component';
import { NoOpenDaysComponent } from '../no-open-days/no-open-days.component';
@Component({
	selector: 'uc-university-events',
	standalone: true,
	templateUrl: './university-events.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [HeadingComponent, EventCardComponent, NoOpenDaysComponent],
})
export class UniversityEventsComponent {
	@Input({ required: true }) paidFeatures!: ClientFeatureOptions[];
	@Input({ required: true }) rating!: number | undefined;
	@Input({ required: true }) product!: Product;
	@Input({ required: true }) events: UniversityEvent[] = [];
	@Input({ required: true }) uniName!: string;
	@Input({ required: true }) externalEventsUrl!: string;
	@Input({ required: true }) uniId!: number;
	@Input({ required: true }) ugSearchOrderPosition!: number | null;
	@Input() uniLogo!: string | undefined;
	@Input() componentName = 'Event Ticket';
	universityUrl = this._route.parent?.snapshot.params['uniName'];

	constructor(private _route: ActivatedRoute) {}
}
