import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CalendarSvgComponent } from '@uc/shared/svg';
import { Product } from '@uc/web/shared/data-models';
import { VisitWebsiteBtnComponent } from '../buttons/visit-website-btn/visit-website-btn.component';

import { BookEventBtnComponent } from '../buttons/book-event-btn/book-event-btn.component';

@Component({
	selector: 'uc-no-open-days',
	standalone: true,
	imports: [CalendarSvgComponent, VisitWebsiteBtnComponent, BookEventBtnComponent],
	templateUrl: './no-open-days.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoOpenDaysComponent {
	@Input({ required: true }) uniName!: string;
	@Input({ required: true }) uniId!: number;
	@Input({ required: true }) externalUrl!: string;
	@Input({ required: true }) product!: Product;
	@Input({ required: true }) isFeatured!: boolean;
	@Input({ required: true }) ugSearchOrderPosition!: number | null;
}
