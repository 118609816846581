import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentApiService } from '@uc/utilities';
import { PageData } from './models/page-content.models';
import { map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PagesApiService {
	constructor(
		private _http: HttpClient,
		private _apiSrv: EnvironmentApiService,
	) {}

	getPageContent(url: string) {
		return this._http.get<PageData>(this._apiSrv.API_URL + 'pages/' + url);
	}
}
