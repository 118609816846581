<div
	class="flex flex-col items-center px-6 py-12 text-center bg-white border border-gray-200 rounded-lg"
>
	<uc-calendar-svg customStyles="!text-gray-500" />
	<h3 class="mt-6 text-xl font-bold leading-6">
		Keep up to date with upcoming events at {{ uniName }}
	</h3>
	<p
		class="max-w-4xl m-auto mt-3 mb-6 text-justify text-gray-500 sm:px-6 sm:text-center"
	>
		While there's currently no scheduled events, head over to the university's website
		to find out how to stay up to date with their open days and more.
	</p>
	<uc-book-event-btn
		buttonText="Learn more"
		componentName="Event Empty State"
		[uniId]="uniId"
		[product]="product"
		[link]="externalUrl"
		class="block w-full"
		customStyles="block w-full sm:w-auto"
		[ugSearchOrderPosition]="ugSearchOrderPosition"
		[uniName]="uniName"
	/>
</div>
