<section
	aria-hidden="true"
	aria-label="Unable to Save"
	class="relative mx-auto bg-white rounded-lg shadow-lg max-w-max"
>
	<uc-close-modal />
	<div class="flex flex-col items-center justify-center p-6 max-w-[400px]">
		<uc-exclamation-mark-triangular-round-bg-svg />
		<div class="mt-5 text-center">
			<h3 class="text-lg font-semibold text-gray-900">Unable to Save</h3>
			<p class="text-sm text-gray-500">
				Universities and courses can only be saved by general users.
			</p>
			<div class="flex gap-3 mt-8">
				<uc-secondary-blue-btn
					[button]="true"
					(click)="closeModalPanel()"
					buttonText="Close"
					class="w-full"
					customStyles="w-full"
				/>
			</div>
		</div>
	</div>
</section>
