import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Product } from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-event-card-header',
	standalone: true,
	templateUrl: './event-card-header.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [RouterModule],
})
export class EventCardHeaderComponent implements OnInit {
	@Input() postgraduateProduct!: number;
	@Input() undergraduateProduct!: number;
	@Input() externalUrl!: string;
	@Input() uniName!: string;
	product: Product = 'undergraduate';

	ngOnInit(): void {
		if (this.postgraduateProduct === 1 && this.undergraduateProduct === 0) {
			this.product = 'postgraduate';
		}
	}
}
