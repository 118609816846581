import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentApiService } from '@uc/utilities';
import { Observable } from 'rxjs';
import { ClientsDropdownList } from './models/clients-dropdown-list.models';

@Injectable({ providedIn: 'root' })
export class ClientsDropdownListApiService {
	constructor(
		private _http: HttpClient,
		private _apiSrv: EnvironmentApiService,
	) {}

	getClientsDropdownList(): Observable<ClientsDropdownList[]> {
		return this._http.get<ClientsDropdownList[]>(
			`${this._apiSrv.API_URL}universities`,
		);
	}
}
