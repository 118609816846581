import { Injectable } from '@angular/core';
import {
	RecommendedClearingUniversity,
	RecommendedUniversity,
	UppercaseProduct,
} from '@uc/web/shared/data-models';
import { Apollo } from 'apollo-angular';
import { GET_CLEARING_RECOMMENDED_UNIS, GET_RECOMMENDED_UNIS } from './queries.graphql';
import { catchError, EMPTY, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class RecommendedUniversitiesApiService {
	constructor(private _apollo: Apollo) {}

	getRecommendedUnis(product: UppercaseProduct) {
		return this._apollo
			.query<{
				recommendedUniversities: RecommendedUniversity[];
			}>({
				query: GET_RECOMMENDED_UNIS,
				variables: { product },
			})
			.pipe(
				catchError((error) => {
					console.error(error);
					return EMPTY;
				}),
				map(({ data }) => {
					return data.recommendedUniversities;
				}),
			);
	}

	getClearingRecommendedUnis() {
		return this._apollo
			.query<{ clearingRecommendedUniversities: RecommendedClearingUniversity[] }>({
				query: GET_CLEARING_RECOMMENDED_UNIS,
			})
			.pipe(
				catchError((error) => {
					console.error(error);
					return EMPTY;
				}),
				map(({ data }) => {
					return data.clearingRecommendedUniversities;
				}),
			);
	}
}
