<div class="relative" [ngClass]="customStyles">
	<!-- Background gray on the right section of the screen -->
	<div
		class="absolute top-0 right-0 hidden w-1/2 h-full ml-auto md:block bg-gray-50"
	></div>
	<div
		class="container flex flex-col justify-center md:py-16 py-8 min-h-[calc(100vh_-_var(--secondary-footer-height))]"
	>
		<div class="md:flex md:items-start sm:my-auto">
			<!-- Mobile & Left Panel content on desktop -->
			<section class="w-full md:w-1/2 md:pr-12 md:border-r md:border-gray-200">
				<ng-content></ng-content>
			</section>

			<!-- right panel -->
			<ng-content select="[rightPanel]"></ng-content>
		</div>
	</div>
</div>
@if (!isInAppBrowser) {
	<footer
		#footer
		class="w-full px-4 py-6 text-base leading-6 text-center text-gray-500 border-t border-gray-200"
	>
		&copy; 2012 - {{ currentYear }} Uni Compare Ltd. All rights reserved.
	</footer>
}
