import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ShareArticleService {
	createShareLink = (
		type: string,
		title: string,
		url: string,
		image = '',
	): null | string => {
		if (!title || !url || !type) return null;

		let linkText = null;
		const encodedTitle = encodeURI(title);
		const encodedImage = encodeURI(image);
		const completeUrl = url;

		switch (type) {
			case 'twitter':
				linkText = `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${completeUrl}`;
				break;
			case 'facebook':
				linkText = `https://www.facebook.com/share.php?u=${completeUrl}&quote=${encodedTitle}`;
				break;
			case 'linkedin':
				linkText = `https://www.linkedin.com/sharing/share-offsite/?url=${completeUrl}`;
				break;
			case 'pinterest':
				linkText = `http://pinterest.com/pin/create/button/?url=${completeUrl}&media=${encodedImage}&description=${encodedTitle}`;
				break;
			case 'email':
				linkText = `mailto:?subject=${encodedTitle}&body=${completeUrl}`;
				break;
		}

		return linkText;
	};
}
