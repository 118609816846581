import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
	selector: 'uc-seo-pagination',
	standalone: true,
	templateUrl: './seo-pagination.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class SeoPaginationComponent implements OnInit {
	@Input({ required: true }) link!: string;
	@Input({ required: true }) hasMorePages!: boolean;
	@Input() queryParams: Params | null = null;
	currentPage = +this.route.parent?.snapshot.queryParams['page'] || 1;
	queryParamsUrl = '';

	constructor(private route: ActivatedRoute) {}

	ngOnInit(): void {
		const queryParamsUrl = this._transformQueryParamsToString();
		this.queryParamsUrl = queryParamsUrl ? `?${queryParamsUrl}` : '';
	}

	generateLink(direction: 'next' | 'prev') {
		let queryParams = this._getQueryParamsString();
		const offset = direction === 'prev' && this.currentPage <= 2 ? '' : '&offset=1';
		let page: number | string = '';

		if (direction === 'next' && this.hasMorePages) {
			page = `?page=${+this.currentPage + 1}`;
		} else if (direction === 'prev' && this.currentPage > 2) {
			page = `?page=${+this.currentPage - 1}`;
		}

		if (page === '' && offset === '') {
			queryParams = queryParams.replace('&', '?');
		}

		return `/${this.link}${page}${offset}${queryParams}`;
	}

	private _transformQueryParamsToString() {
		if (this.queryParams !== null && typeof this.queryParams === 'object') {
			const filteredParams = Object.entries(this.queryParams)
				.filter(([key]) => key !== 'page' && key !== 'offset')
				.map(([key, value]) => `${key}=${value}`);

			if (filteredParams.length > 0) {
				return `${filteredParams.join('&')}`;
			}
		}

		return '';
	}

	private _getQueryParamsString() {
		const queryParamsString = this._transformQueryParamsToString();
		return queryParamsString ? `&${queryParamsString}` : '';
	}
}
