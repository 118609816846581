import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { DestructiveActionModalComponent } from './destructive-action-modal.component';
import { ModalService } from '@uc/shared/ui';

@Injectable({ providedIn: 'root' })
export class DestructiveActionModalService {
	destroyForm = new Subject<boolean>();

	constructor(private _modalSrv: ModalService) {}

	continueForm() {
		this.destroyForm.next(false);
	}

	exitForm() {
		this.destroyForm.next(true);
	}

	checkFormStarted(form: FormGroup, formStarted = false) {
		if ((form.dirty && form.invalid) || formStarted) {
			this._modalSrv.setContent(DestructiveActionModalComponent);
			this._modalSrv.show();
			return this.destroyForm;
		}
		return true;
	}
}
