import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomSelectComponent } from '@uc/shared/ui';
import { FormOptionsService } from '@uc/utilities';

@Component({
	selector: 'uc-application-details',
	standalone: true,
	templateUrl: './application-details.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CustomSelectComponent, FormsModule, ReactiveFormsModule],
})
export class ApplicationDetailsComponent {
	@Input() title = false;
	@Input() showTooltip!: boolean;
	@Input() parentForm!: FormGroup;
	@Input() subjects: any;
	@Input() formSubmitted!: boolean;

	degrees = this.formSrv.getDegreeOptionsConfig();
	years = this.formSrv.getStartYearConfig();

	constructor(private formSrv: FormOptionsService) {}
}
