import { __assign, __awaiter, __generator } from "tslib";
import { invariant, newInvariantError } from '../utilities/globals/index.js';
import { equal } from '@wry/equality';
import { execute } from '../link/core/index.js';
import { hasDirectives, isExecutionPatchIncrementalResult, isExecutionPatchResult, removeDirectivesFromDocument } from '../utilities/index.js';
import { canonicalStringify } from '../cache/index.js';
import { getDefaultValues, getOperationDefinition, getOperationName, hasClientExports, graphQLResultHasError, getGraphQLErrorsFromResult, canUseWeakMap, Observable, asyncMap, isNonEmptyArray, Concast, makeUniqueId, isDocumentNode, isNonNullObject, DocumentTransform } from '../utilities/index.js';
import { mergeIncrementalData } from '../utilities/common/incrementalResult.js';
import { ApolloError, isApolloError, graphQLResultHasProtocolErrors } from '../errors/index.js';
import { ObservableQuery, logMissingFieldErrors } from './ObservableQuery.js';
import { NetworkStatus, isNetworkRequestInFlight } from './networkStatus.js';
import { LocalState } from './LocalState.js';
import { QueryInfo, shouldWriteResult } from './QueryInfo.js';
import { PROTOCOL_ERRORS_SYMBOL } from '../errors/index.js';
import { print } from '../utilities/index.js';
var hasOwnProperty = Object.prototype.hasOwnProperty;
var QueryManager = function () {
  function QueryManager(_a) {
    var cache = _a.cache,
      link = _a.link,
      defaultOptions = _a.defaultOptions,
      documentTransform = _a.documentTransform,
      _b = _a.queryDeduplication,
      queryDeduplication = _b === void 0 ? false : _b,
      onBroadcast = _a.onBroadcast,
      _c = _a.ssrMode,
      ssrMode = _c === void 0 ? false : _c,
      _d = _a.clientAwareness,
      clientAwareness = _d === void 0 ? {} : _d,
      localState = _a.localState,
      _e = _a.assumeImmutableResults,
      assumeImmutableResults = _e === void 0 ? !!cache.assumeImmutableResults : _e;
    var _this = this;
    this.clientAwareness = {};
    this.queries = new Map();
    this.fetchCancelFns = new Map();
    this.transformCache = new (canUseWeakMap ? WeakMap : Map)();
    this.queryIdCounter = 1;
    this.requestIdCounter = 1;
    this.mutationIdCounter = 1;
    this.inFlightLinkObservables = new Map();
    var defaultDocumentTransform = new DocumentTransform(function (document) {
      return _this.cache.transformDocument(document);
    }, {
      cache: false
    });
    this.cache = cache;
    this.link = link;
    this.defaultOptions = defaultOptions || Object.create(null);
    this.queryDeduplication = queryDeduplication;
    this.clientAwareness = clientAwareness;
    this.localState = localState || new LocalState({
      cache: cache
    });
    this.ssrMode = ssrMode;
    this.assumeImmutableResults = assumeImmutableResults;
    this.documentTransform = documentTransform ? defaultDocumentTransform.concat(documentTransform).concat(defaultDocumentTransform) : defaultDocumentTransform;
    if (this.onBroadcast = onBroadcast) {
      this.mutationStore = Object.create(null);
    }
  }
  QueryManager.prototype.stop = function () {
    var _this = this;
    this.queries.forEach(function (_info, queryId) {
      _this.stopQueryNoBroadcast(queryId);
    });
    this.cancelPendingFetches(newInvariantError(23));
  };
  QueryManager.prototype.cancelPendingFetches = function (error) {
    this.fetchCancelFns.forEach(function (cancel) {
      return cancel(error);
    });
    this.fetchCancelFns.clear();
  };
  QueryManager.prototype.mutate = function (_a) {
    var _b, _c;
    var mutation = _a.mutation,
      variables = _a.variables,
      optimisticResponse = _a.optimisticResponse,
      updateQueries = _a.updateQueries,
      _d = _a.refetchQueries,
      refetchQueries = _d === void 0 ? [] : _d,
      _e = _a.awaitRefetchQueries,
      awaitRefetchQueries = _e === void 0 ? false : _e,
      updateWithProxyFn = _a.update,
      onQueryUpdated = _a.onQueryUpdated,
      _f = _a.fetchPolicy,
      fetchPolicy = _f === void 0 ? ((_b = this.defaultOptions.mutate) === null || _b === void 0 ? void 0 : _b.fetchPolicy) || "network-only" : _f,
      _g = _a.errorPolicy,
      errorPolicy = _g === void 0 ? ((_c = this.defaultOptions.mutate) === null || _c === void 0 ? void 0 : _c.errorPolicy) || "none" : _g,
      keepRootFields = _a.keepRootFields,
      context = _a.context;
    return __awaiter(this, void 0, void 0, function () {
      var mutationId, hasClientExports, mutationStoreValue, self;
      return __generator(this, function (_h) {
        switch (_h.label) {
          case 0:
            invariant(mutation, 24);
            invariant(fetchPolicy === 'network-only' || fetchPolicy === 'no-cache', 25);
            mutationId = this.generateMutationId();
            mutation = this.cache.transformForLink(this.transform(mutation));
            hasClientExports = this.getDocumentInfo(mutation).hasClientExports;
            variables = this.getVariables(mutation, variables);
            if (!hasClientExports) return [3, 2];
            return [4, this.localState.addExportedVariables(mutation, variables, context)];
          case 1:
            variables = _h.sent();
            _h.label = 2;
          case 2:
            mutationStoreValue = this.mutationStore && (this.mutationStore[mutationId] = {
              mutation: mutation,
              variables: variables,
              loading: true,
              error: null
            });
            if (optimisticResponse) {
              this.markMutationOptimistic(optimisticResponse, {
                mutationId: mutationId,
                document: mutation,
                variables: variables,
                fetchPolicy: fetchPolicy,
                errorPolicy: errorPolicy,
                context: context,
                updateQueries: updateQueries,
                update: updateWithProxyFn,
                keepRootFields: keepRootFields
              });
            }
            this.broadcastQueries();
            self = this;
            return [2, new Promise(function (resolve, reject) {
              return asyncMap(self.getObservableFromLink(mutation, __assign(__assign({}, context), {
                optimisticResponse: optimisticResponse
              }), variables, false), function (result) {
                if (graphQLResultHasError(result) && errorPolicy === 'none') {
                  throw new ApolloError({
                    graphQLErrors: getGraphQLErrorsFromResult(result)
                  });
                }
                if (mutationStoreValue) {
                  mutationStoreValue.loading = false;
                  mutationStoreValue.error = null;
                }
                var storeResult = __assign({}, result);
                if (typeof refetchQueries === "function") {
                  refetchQueries = refetchQueries(storeResult);
                }
                if (errorPolicy === 'ignore' && graphQLResultHasError(storeResult)) {
                  delete storeResult.errors;
                }
                return self.markMutationResult({
                  mutationId: mutationId,
                  result: storeResult,
                  document: mutation,
                  variables: variables,
                  fetchPolicy: fetchPolicy,
                  errorPolicy: errorPolicy,
                  context: context,
                  update: updateWithProxyFn,
                  updateQueries: updateQueries,
                  awaitRefetchQueries: awaitRefetchQueries,
                  refetchQueries: refetchQueries,
                  removeOptimistic: optimisticResponse ? mutationId : void 0,
                  onQueryUpdated: onQueryUpdated,
                  keepRootFields: keepRootFields
                });
              }).subscribe({
                next: function (storeResult) {
                  self.broadcastQueries();
                  if (!('hasNext' in storeResult) || storeResult.hasNext === false) {
                    resolve(storeResult);
                  }
                },
                error: function (err) {
                  if (mutationStoreValue) {
                    mutationStoreValue.loading = false;
                    mutationStoreValue.error = err;
                  }
                  if (optimisticResponse) {
                    self.cache.removeOptimistic(mutationId);
                  }
                  self.broadcastQueries();
                  reject(err instanceof ApolloError ? err : new ApolloError({
                    networkError: err
                  }));
                }
              });
            })];
        }
      });
    });
  };
  QueryManager.prototype.markMutationResult = function (mutation, cache) {
    var _this = this;
    if (cache === void 0) {
      cache = this.cache;
    }
    var result = mutation.result;
    var cacheWrites = [];
    var skipCache = mutation.fetchPolicy === "no-cache";
    if (!skipCache && shouldWriteResult(result, mutation.errorPolicy)) {
      if (!isExecutionPatchIncrementalResult(result)) {
        cacheWrites.push({
          result: result.data,
          dataId: 'ROOT_MUTATION',
          query: mutation.document,
          variables: mutation.variables
        });
      }
      if (isExecutionPatchIncrementalResult(result) && isNonEmptyArray(result.incremental)) {
        var diff = cache.diff({
          id: "ROOT_MUTATION",
          query: this.getDocumentInfo(mutation.document).asQuery,
          variables: mutation.variables,
          optimistic: false,
          returnPartialData: true
        });
        var mergedData = void 0;
        if (diff.result) {
          mergedData = mergeIncrementalData(diff.result, result);
        }
        if (typeof mergedData !== 'undefined') {
          result.data = mergedData;
          cacheWrites.push({
            result: mergedData,
            dataId: 'ROOT_MUTATION',
            query: mutation.document,
            variables: mutation.variables
          });
        }
      }
      var updateQueries_1 = mutation.updateQueries;
      if (updateQueries_1) {
        this.queries.forEach(function (_a, queryId) {
          var observableQuery = _a.observableQuery;
          var queryName = observableQuery && observableQuery.queryName;
          if (!queryName || !hasOwnProperty.call(updateQueries_1, queryName)) {
            return;
          }
          var updater = updateQueries_1[queryName];
          var _b = _this.queries.get(queryId),
            document = _b.document,
            variables = _b.variables;
          var _c = cache.diff({
              query: document,
              variables: variables,
              returnPartialData: true,
              optimistic: false
            }),
            currentQueryResult = _c.result,
            complete = _c.complete;
          if (complete && currentQueryResult) {
            var nextQueryResult = updater(currentQueryResult, {
              mutationResult: result,
              queryName: document && getOperationName(document) || void 0,
              queryVariables: variables
            });
            if (nextQueryResult) {
              cacheWrites.push({
                result: nextQueryResult,
                dataId: 'ROOT_QUERY',
                query: document,
                variables: variables
              });
            }
          }
        });
      }
    }
    if (cacheWrites.length > 0 || mutation.refetchQueries || mutation.update || mutation.onQueryUpdated || mutation.removeOptimistic) {
      var results_1 = [];
      this.refetchQueries({
        updateCache: function (cache) {
          if (!skipCache) {
            cacheWrites.forEach(function (write) {
              return cache.write(write);
            });
          }
          var update = mutation.update;
          var isFinalResult = !isExecutionPatchResult(result) || isExecutionPatchIncrementalResult(result) && !result.hasNext;
          if (update) {
            if (!skipCache) {
              var diff = cache.diff({
                id: "ROOT_MUTATION",
                query: _this.getDocumentInfo(mutation.document).asQuery,
                variables: mutation.variables,
                optimistic: false,
                returnPartialData: true
              });
              if (diff.complete) {
                result = __assign(__assign({}, result), {
                  data: diff.result
                });
                if ('incremental' in result) {
                  delete result.incremental;
                }
                if ('hasNext' in result) {
                  delete result.hasNext;
                }
              }
            }
            if (isFinalResult) {
              update(cache, result, {
                context: mutation.context,
                variables: mutation.variables
              });
            }
          }
          if (!skipCache && !mutation.keepRootFields && isFinalResult) {
            cache.modify({
              id: 'ROOT_MUTATION',
              fields: function (value, _a) {
                var fieldName = _a.fieldName,
                  DELETE = _a.DELETE;
                return fieldName === "__typename" ? value : DELETE;
              }
            });
          }
        },
        include: mutation.refetchQueries,
        optimistic: false,
        removeOptimistic: mutation.removeOptimistic,
        onQueryUpdated: mutation.onQueryUpdated || null
      }).forEach(function (result) {
        return results_1.push(result);
      });
      if (mutation.awaitRefetchQueries || mutation.onQueryUpdated) {
        return Promise.all(results_1).then(function () {
          return result;
        });
      }
    }
    return Promise.resolve(result);
  };
  QueryManager.prototype.markMutationOptimistic = function (optimisticResponse, mutation) {
    var _this = this;
    var data = typeof optimisticResponse === "function" ? optimisticResponse(mutation.variables) : optimisticResponse;
    return this.cache.recordOptimisticTransaction(function (cache) {
      try {
        _this.markMutationResult(__assign(__assign({}, mutation), {
          result: {
            data: data
          }
        }), cache);
      } catch (error) {
        globalThis.__DEV__ !== false && invariant.error(error);
      }
    }, mutation.mutationId);
  };
  QueryManager.prototype.fetchQuery = function (queryId, options, networkStatus) {
    return this.fetchConcastWithInfo(queryId, options, networkStatus).concast.promise;
  };
  QueryManager.prototype.getQueryStore = function () {
    var store = Object.create(null);
    this.queries.forEach(function (info, queryId) {
      store[queryId] = {
        variables: info.variables,
        networkStatus: info.networkStatus,
        networkError: info.networkError,
        graphQLErrors: info.graphQLErrors
      };
    });
    return store;
  };
  QueryManager.prototype.resetErrors = function (queryId) {
    var queryInfo = this.queries.get(queryId);
    if (queryInfo) {
      queryInfo.networkError = undefined;
      queryInfo.graphQLErrors = [];
    }
  };
  QueryManager.prototype.transform = function (document) {
    return this.documentTransform.transformDocument(document);
  };
  QueryManager.prototype.getDocumentInfo = function (document) {
    var transformCache = this.transformCache;
    if (!transformCache.has(document)) {
      var cacheEntry = {
        hasClientExports: hasClientExports(document),
        hasForcedResolvers: this.localState.shouldForceResolvers(document),
        hasNonreactiveDirective: hasDirectives(['nonreactive'], document),
        clientQuery: this.localState.clientQuery(document),
        serverQuery: removeDirectivesFromDocument([{
          name: 'client',
          remove: true
        }, {
          name: 'connection'
        }, {
          name: 'nonreactive'
        }], document),
        defaultVars: getDefaultValues(getOperationDefinition(document)),
        asQuery: __assign(__assign({}, document), {
          definitions: document.definitions.map(function (def) {
            if (def.kind === "OperationDefinition" && def.operation !== "query") {
              return __assign(__assign({}, def), {
                operation: "query"
              });
            }
            return def;
          })
        })
      };
      transformCache.set(document, cacheEntry);
    }
    return transformCache.get(document);
  };
  QueryManager.prototype.getVariables = function (document, variables) {
    return __assign(__assign({}, this.getDocumentInfo(document).defaultVars), variables);
  };
  QueryManager.prototype.watchQuery = function (options) {
    var query = this.transform(options.query);
    options = __assign(__assign({}, options), {
      variables: this.getVariables(query, options.variables)
    });
    if (typeof options.notifyOnNetworkStatusChange === 'undefined') {
      options.notifyOnNetworkStatusChange = false;
    }
    var queryInfo = new QueryInfo(this);
    var observable = new ObservableQuery({
      queryManager: this,
      queryInfo: queryInfo,
      options: options
    });
    observable['lastQuery'] = query;
    this.queries.set(observable.queryId, queryInfo);
    queryInfo.init({
      document: query,
      observableQuery: observable,
      variables: observable.variables
    });
    return observable;
  };
  QueryManager.prototype.query = function (options, queryId) {
    var _this = this;
    if (queryId === void 0) {
      queryId = this.generateQueryId();
    }
    invariant(options.query, 26);
    invariant(options.query.kind === 'Document', 27);
    invariant(!options.returnPartialData, 28);
    invariant(!options.pollInterval, 29);
    return this.fetchQuery(queryId, __assign(__assign({}, options), {
      query: this.transform(options.query)
    })).finally(function () {
      return _this.stopQuery(queryId);
    });
  };
  QueryManager.prototype.generateQueryId = function () {
    return String(this.queryIdCounter++);
  };
  QueryManager.prototype.generateRequestId = function () {
    return this.requestIdCounter++;
  };
  QueryManager.prototype.generateMutationId = function () {
    return String(this.mutationIdCounter++);
  };
  QueryManager.prototype.stopQueryInStore = function (queryId) {
    this.stopQueryInStoreNoBroadcast(queryId);
    this.broadcastQueries();
  };
  QueryManager.prototype.stopQueryInStoreNoBroadcast = function (queryId) {
    var queryInfo = this.queries.get(queryId);
    if (queryInfo) queryInfo.stop();
  };
  QueryManager.prototype.clearStore = function (options) {
    if (options === void 0) {
      options = {
        discardWatches: true
      };
    }
    this.cancelPendingFetches(newInvariantError(30));
    this.queries.forEach(function (queryInfo) {
      if (queryInfo.observableQuery) {
        queryInfo.networkStatus = NetworkStatus.loading;
      } else {
        queryInfo.stop();
      }
    });
    if (this.mutationStore) {
      this.mutationStore = Object.create(null);
    }
    return this.cache.reset(options);
  };
  QueryManager.prototype.getObservableQueries = function (include) {
    var _this = this;
    if (include === void 0) {
      include = "active";
    }
    var queries = new Map();
    var queryNamesAndDocs = new Map();
    var legacyQueryOptions = new Set();
    if (Array.isArray(include)) {
      include.forEach(function (desc) {
        if (typeof desc === "string") {
          queryNamesAndDocs.set(desc, false);
        } else if (isDocumentNode(desc)) {
          queryNamesAndDocs.set(_this.transform(desc), false);
        } else if (isNonNullObject(desc) && desc.query) {
          legacyQueryOptions.add(desc);
        }
      });
    }
    this.queries.forEach(function (_a, queryId) {
      var oq = _a.observableQuery,
        document = _a.document;
      if (oq) {
        if (include === "all") {
          queries.set(queryId, oq);
          return;
        }
        var queryName = oq.queryName,
          fetchPolicy = oq.options.fetchPolicy;
        if (fetchPolicy === "standby" || include === "active" && !oq.hasObservers()) {
          return;
        }
        if (include === "active" || queryName && queryNamesAndDocs.has(queryName) || document && queryNamesAndDocs.has(document)) {
          queries.set(queryId, oq);
          if (queryName) queryNamesAndDocs.set(queryName, true);
          if (document) queryNamesAndDocs.set(document, true);
        }
      }
    });
    if (legacyQueryOptions.size) {
      legacyQueryOptions.forEach(function (options) {
        var queryId = makeUniqueId("legacyOneTimeQuery");
        var queryInfo = _this.getQuery(queryId).init({
          document: options.query,
          variables: options.variables
        });
        var oq = new ObservableQuery({
          queryManager: _this,
          queryInfo: queryInfo,
          options: __assign(__assign({}, options), {
            fetchPolicy: "network-only"
          })
        });
        invariant(oq.queryId === queryId);
        queryInfo.setObservableQuery(oq);
        queries.set(queryId, oq);
      });
    }
    if (globalThis.__DEV__ !== false && queryNamesAndDocs.size) {
      queryNamesAndDocs.forEach(function (included, nameOrDoc) {
        if (!included) {
          globalThis.__DEV__ !== false && invariant.warn(typeof nameOrDoc === "string" ? 31 : 32, nameOrDoc);
        }
      });
    }
    return queries;
  };
  QueryManager.prototype.reFetchObservableQueries = function (includeStandby) {
    var _this = this;
    if (includeStandby === void 0) {
      includeStandby = false;
    }
    var observableQueryPromises = [];
    this.getObservableQueries(includeStandby ? "all" : "active").forEach(function (observableQuery, queryId) {
      var fetchPolicy = observableQuery.options.fetchPolicy;
      observableQuery.resetLastResults();
      if (includeStandby || fetchPolicy !== "standby" && fetchPolicy !== "cache-only") {
        observableQueryPromises.push(observableQuery.refetch());
      }
      _this.getQuery(queryId).setDiff(null);
    });
    this.broadcastQueries();
    return Promise.all(observableQueryPromises);
  };
  QueryManager.prototype.setObservableQuery = function (observableQuery) {
    this.getQuery(observableQuery.queryId).setObservableQuery(observableQuery);
  };
  QueryManager.prototype.startGraphQLSubscription = function (_a) {
    var _this = this;
    var query = _a.query,
      fetchPolicy = _a.fetchPolicy,
      errorPolicy = _a.errorPolicy,
      variables = _a.variables,
      _b = _a.context,
      context = _b === void 0 ? {} : _b;
    query = this.transform(query);
    variables = this.getVariables(query, variables);
    var makeObservable = function (variables) {
      return _this.getObservableFromLink(query, context, variables).map(function (result) {
        if (fetchPolicy !== 'no-cache') {
          if (shouldWriteResult(result, errorPolicy)) {
            _this.cache.write({
              query: query,
              result: result.data,
              dataId: 'ROOT_SUBSCRIPTION',
              variables: variables
            });
          }
          _this.broadcastQueries();
        }
        var hasErrors = graphQLResultHasError(result);
        var hasProtocolErrors = graphQLResultHasProtocolErrors(result);
        if (hasErrors || hasProtocolErrors) {
          var errors = {};
          if (hasErrors) {
            errors.graphQLErrors = result.errors;
          }
          if (hasProtocolErrors) {
            errors.protocolErrors = result.extensions[PROTOCOL_ERRORS_SYMBOL];
          }
          throw new ApolloError(errors);
        }
        return result;
      });
    };
    if (this.getDocumentInfo(query).hasClientExports) {
      var observablePromise_1 = this.localState.addExportedVariables(query, variables, context).then(makeObservable);
      return new Observable(function (observer) {
        var sub = null;
        observablePromise_1.then(function (observable) {
          return sub = observable.subscribe(observer);
        }, observer.error);
        return function () {
          return sub && sub.unsubscribe();
        };
      });
    }
    return makeObservable(variables);
  };
  QueryManager.prototype.stopQuery = function (queryId) {
    this.stopQueryNoBroadcast(queryId);
    this.broadcastQueries();
  };
  QueryManager.prototype.stopQueryNoBroadcast = function (queryId) {
    this.stopQueryInStoreNoBroadcast(queryId);
    this.removeQuery(queryId);
  };
  QueryManager.prototype.removeQuery = function (queryId) {
    this.fetchCancelFns.delete(queryId);
    if (this.queries.has(queryId)) {
      this.getQuery(queryId).stop();
      this.queries.delete(queryId);
    }
  };
  QueryManager.prototype.broadcastQueries = function () {
    if (this.onBroadcast) this.onBroadcast();
    this.queries.forEach(function (info) {
      return info.notify();
    });
  };
  QueryManager.prototype.getLocalState = function () {
    return this.localState;
  };
  QueryManager.prototype.getObservableFromLink = function (query, context, variables, deduplication) {
    var _this = this;
    var _a;
    if (deduplication === void 0) {
      deduplication = (_a = context === null || context === void 0 ? void 0 : context.queryDeduplication) !== null && _a !== void 0 ? _a : this.queryDeduplication;
    }
    var observable;
    var _b = this.getDocumentInfo(query),
      serverQuery = _b.serverQuery,
      clientQuery = _b.clientQuery;
    if (serverQuery) {
      var _c = this,
        inFlightLinkObservables_1 = _c.inFlightLinkObservables,
        link = _c.link;
      var operation = {
        query: serverQuery,
        variables: variables,
        operationName: getOperationName(serverQuery) || void 0,
        context: this.prepareContext(__assign(__assign({}, context), {
          forceFetch: !deduplication
        }))
      };
      context = operation.context;
      if (deduplication) {
        var printedServerQuery_1 = print(serverQuery);
        var byVariables_1 = inFlightLinkObservables_1.get(printedServerQuery_1) || new Map();
        inFlightLinkObservables_1.set(printedServerQuery_1, byVariables_1);
        var varJson_1 = canonicalStringify(variables);
        observable = byVariables_1.get(varJson_1);
        if (!observable) {
          var concast = new Concast([execute(link, operation)]);
          byVariables_1.set(varJson_1, observable = concast);
          concast.beforeNext(function () {
            if (byVariables_1.delete(varJson_1) && byVariables_1.size < 1) {
              inFlightLinkObservables_1.delete(printedServerQuery_1);
            }
          });
        }
      } else {
        observable = new Concast([execute(link, operation)]);
      }
    } else {
      observable = new Concast([Observable.of({
        data: {}
      })]);
      context = this.prepareContext(context);
    }
    if (clientQuery) {
      observable = asyncMap(observable, function (result) {
        return _this.localState.runResolvers({
          document: clientQuery,
          remoteResult: result,
          context: context,
          variables: variables
        });
      });
    }
    return observable;
  };
  QueryManager.prototype.getResultsFromLink = function (queryInfo, cacheWriteBehavior, options) {
    var requestId = queryInfo.lastRequestId = this.generateRequestId();
    var linkDocument = this.cache.transformForLink(options.query);
    return asyncMap(this.getObservableFromLink(linkDocument, options.context, options.variables), function (result) {
      var graphQLErrors = getGraphQLErrorsFromResult(result);
      var hasErrors = graphQLErrors.length > 0;
      if (requestId >= queryInfo.lastRequestId) {
        if (hasErrors && options.errorPolicy === "none") {
          throw queryInfo.markError(new ApolloError({
            graphQLErrors: graphQLErrors
          }));
        }
        queryInfo.markResult(result, linkDocument, options, cacheWriteBehavior);
        queryInfo.markReady();
      }
      var aqr = {
        data: result.data,
        loading: false,
        networkStatus: NetworkStatus.ready
      };
      if (hasErrors && options.errorPolicy !== "ignore") {
        aqr.errors = graphQLErrors;
        aqr.networkStatus = NetworkStatus.error;
      }
      return aqr;
    }, function (networkError) {
      var error = isApolloError(networkError) ? networkError : new ApolloError({
        networkError: networkError
      });
      if (requestId >= queryInfo.lastRequestId) {
        queryInfo.markError(error);
      }
      throw error;
    });
  };
  QueryManager.prototype.fetchConcastWithInfo = function (queryId, options, networkStatus) {
    var _this = this;
    if (networkStatus === void 0) {
      networkStatus = NetworkStatus.loading;
    }
    var query = options.query;
    var variables = this.getVariables(query, options.variables);
    var queryInfo = this.getQuery(queryId);
    var defaults = this.defaultOptions.watchQuery;
    var _a = options.fetchPolicy,
      fetchPolicy = _a === void 0 ? defaults && defaults.fetchPolicy || "cache-first" : _a,
      _b = options.errorPolicy,
      errorPolicy = _b === void 0 ? defaults && defaults.errorPolicy || "none" : _b,
      _c = options.returnPartialData,
      returnPartialData = _c === void 0 ? false : _c,
      _d = options.notifyOnNetworkStatusChange,
      notifyOnNetworkStatusChange = _d === void 0 ? false : _d,
      _e = options.context,
      context = _e === void 0 ? {} : _e;
    var normalized = Object.assign({}, options, {
      query: query,
      variables: variables,
      fetchPolicy: fetchPolicy,
      errorPolicy: errorPolicy,
      returnPartialData: returnPartialData,
      notifyOnNetworkStatusChange: notifyOnNetworkStatusChange,
      context: context
    });
    var fromVariables = function (variables) {
      normalized.variables = variables;
      var sourcesWithInfo = _this.fetchQueryByPolicy(queryInfo, normalized, networkStatus);
      if (normalized.fetchPolicy !== "standby" && sourcesWithInfo.sources.length > 0 && queryInfo.observableQuery) {
        queryInfo.observableQuery["applyNextFetchPolicy"]("after-fetch", options);
      }
      return sourcesWithInfo;
    };
    var cleanupCancelFn = function () {
      return _this.fetchCancelFns.delete(queryId);
    };
    this.fetchCancelFns.set(queryId, function (reason) {
      cleanupCancelFn();
      setTimeout(function () {
        return concast.cancel(reason);
      });
    });
    var concast, containsDataFromLink;
    if (this.getDocumentInfo(normalized.query).hasClientExports) {
      concast = new Concast(this.localState.addExportedVariables(normalized.query, normalized.variables, normalized.context).then(fromVariables).then(function (sourcesWithInfo) {
        return sourcesWithInfo.sources;
      }));
      containsDataFromLink = true;
    } else {
      var sourcesWithInfo = fromVariables(normalized.variables);
      containsDataFromLink = sourcesWithInfo.fromLink;
      concast = new Concast(sourcesWithInfo.sources);
    }
    concast.promise.then(cleanupCancelFn, cleanupCancelFn);
    return {
      concast: concast,
      fromLink: containsDataFromLink
    };
  };
  QueryManager.prototype.refetchQueries = function (_a) {
    var _this = this;
    var updateCache = _a.updateCache,
      include = _a.include,
      _b = _a.optimistic,
      optimistic = _b === void 0 ? false : _b,
      _c = _a.removeOptimistic,
      removeOptimistic = _c === void 0 ? optimistic ? makeUniqueId("refetchQueries") : void 0 : _c,
      onQueryUpdated = _a.onQueryUpdated;
    var includedQueriesById = new Map();
    if (include) {
      this.getObservableQueries(include).forEach(function (oq, queryId) {
        includedQueriesById.set(queryId, {
          oq: oq,
          lastDiff: _this.getQuery(queryId).getDiff()
        });
      });
    }
    var results = new Map();
    if (updateCache) {
      this.cache.batch({
        update: updateCache,
        optimistic: optimistic && removeOptimistic || false,
        removeOptimistic: removeOptimistic,
        onWatchUpdated: function (watch, diff, lastDiff) {
          var oq = watch.watcher instanceof QueryInfo && watch.watcher.observableQuery;
          if (oq) {
            if (onQueryUpdated) {
              includedQueriesById.delete(oq.queryId);
              var result = onQueryUpdated(oq, diff, lastDiff);
              if (result === true) {
                result = oq.refetch();
              }
              if (result !== false) {
                results.set(oq, result);
              }
              return result;
            }
            if (onQueryUpdated !== null) {
              includedQueriesById.set(oq.queryId, {
                oq: oq,
                lastDiff: lastDiff,
                diff: diff
              });
            }
          }
        }
      });
    }
    if (includedQueriesById.size) {
      includedQueriesById.forEach(function (_a, queryId) {
        var oq = _a.oq,
          lastDiff = _a.lastDiff,
          diff = _a.diff;
        var result;
        if (onQueryUpdated) {
          if (!diff) {
            var info = oq["queryInfo"];
            info.reset();
            diff = info.getDiff();
          }
          result = onQueryUpdated(oq, diff, lastDiff);
        }
        if (!onQueryUpdated || result === true) {
          result = oq.refetch();
        }
        if (result !== false) {
          results.set(oq, result);
        }
        if (queryId.indexOf("legacyOneTimeQuery") >= 0) {
          _this.stopQueryNoBroadcast(queryId);
        }
      });
    }
    if (removeOptimistic) {
      this.cache.removeOptimistic(removeOptimistic);
    }
    return results;
  };
  QueryManager.prototype.fetchQueryByPolicy = function (queryInfo, _a, networkStatus) {
    var _this = this;
    var query = _a.query,
      variables = _a.variables,
      fetchPolicy = _a.fetchPolicy,
      refetchWritePolicy = _a.refetchWritePolicy,
      errorPolicy = _a.errorPolicy,
      returnPartialData = _a.returnPartialData,
      context = _a.context,
      notifyOnNetworkStatusChange = _a.notifyOnNetworkStatusChange;
    var oldNetworkStatus = queryInfo.networkStatus;
    queryInfo.init({
      document: query,
      variables: variables,
      networkStatus: networkStatus
    });
    var readCache = function () {
      return queryInfo.getDiff(variables);
    };
    var resultsFromCache = function (diff, networkStatus) {
      if (networkStatus === void 0) {
        networkStatus = queryInfo.networkStatus || NetworkStatus.loading;
      }
      var data = diff.result;
      if (globalThis.__DEV__ !== false && !returnPartialData && !equal(data, {})) {
        logMissingFieldErrors(diff.missing);
      }
      var fromData = function (data) {
        return Observable.of(__assign({
          data: data,
          loading: isNetworkRequestInFlight(networkStatus),
          networkStatus: networkStatus
        }, diff.complete ? null : {
          partial: true
        }));
      };
      if (data && _this.getDocumentInfo(query).hasForcedResolvers) {
        return _this.localState.runResolvers({
          document: query,
          remoteResult: {
            data: data
          },
          context: context,
          variables: variables,
          onlyRunForcedResolvers: true
        }).then(function (resolved) {
          return fromData(resolved.data || void 0);
        });
      }
      if (errorPolicy === 'none' && networkStatus === NetworkStatus.refetch && Array.isArray(diff.missing)) {
        return fromData(void 0);
      }
      return fromData(data);
    };
    var cacheWriteBehavior = fetchPolicy === "no-cache" ? 0 : networkStatus === NetworkStatus.refetch && refetchWritePolicy !== "merge" ? 1 : 2;
    var resultsFromLink = function () {
      return _this.getResultsFromLink(queryInfo, cacheWriteBehavior, {
        query: query,
        variables: variables,
        context: context,
        fetchPolicy: fetchPolicy,
        errorPolicy: errorPolicy
      });
    };
    var shouldNotify = notifyOnNetworkStatusChange && typeof oldNetworkStatus === "number" && oldNetworkStatus !== networkStatus && isNetworkRequestInFlight(networkStatus);
    switch (fetchPolicy) {
      default:
      case "cache-first":
        {
          var diff = readCache();
          if (diff.complete) {
            return {
              fromLink: false,
              sources: [resultsFromCache(diff, queryInfo.markReady())]
            };
          }
          if (returnPartialData || shouldNotify) {
            return {
              fromLink: true,
              sources: [resultsFromCache(diff), resultsFromLink()]
            };
          }
          return {
            fromLink: true,
            sources: [resultsFromLink()]
          };
        }
      case "cache-and-network":
        {
          var diff = readCache();
          if (diff.complete || returnPartialData || shouldNotify) {
            return {
              fromLink: true,
              sources: [resultsFromCache(diff), resultsFromLink()]
            };
          }
          return {
            fromLink: true,
            sources: [resultsFromLink()]
          };
        }
      case "cache-only":
        return {
          fromLink: false,
          sources: [resultsFromCache(readCache(), queryInfo.markReady())]
        };
      case "network-only":
        if (shouldNotify) {
          return {
            fromLink: true,
            sources: [resultsFromCache(readCache()), resultsFromLink()]
          };
        }
        return {
          fromLink: true,
          sources: [resultsFromLink()]
        };
      case "no-cache":
        if (shouldNotify) {
          return {
            fromLink: true,
            sources: [resultsFromCache(queryInfo.getDiff()), resultsFromLink()]
          };
        }
        return {
          fromLink: true,
          sources: [resultsFromLink()]
        };
      case "standby":
        return {
          fromLink: false,
          sources: []
        };
    }
  };
  QueryManager.prototype.getQuery = function (queryId) {
    if (queryId && !this.queries.has(queryId)) {
      this.queries.set(queryId, new QueryInfo(this, queryId));
    }
    return this.queries.get(queryId);
  };
  QueryManager.prototype.prepareContext = function (context) {
    if (context === void 0) {
      context = {};
    }
    var newContext = this.localState.prepareContext(context);
    return __assign(__assign({}, newContext), {
      clientAwareness: this.clientAwareness
    });
  };
  return QueryManager;
}();
export { QueryManager };
