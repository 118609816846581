import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadMoreGTMService } from '@uc/web/core';
import { LoadMoreBtnService } from './load-more-btn.service';
import { LoadingStateBtnComponent } from '../loading-state-btn/loading-state-btn.component';
import { RefreshSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-load-more-btn',
	standalone: true,
	templateUrl: './load-more-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [LoadingStateBtnComponent, RefreshSvgComponent],
})
export class LoadMoreBtnComponent {
	@Input() isLoading!: boolean | null;
	@Input() page = 1;
	@Input() searchTerm!: string;

	constructor(
		private _loadMoreSrv: LoadMoreBtnService,
		private _loadMoreGTMSrv: LoadMoreGTMService,
	) {}

	onLoadMore() {
		this.page++;
		this._loadMoreSrv.nextPage(this.page);

		/* GTM Event currently used on course search and uni search page
	       when the load more button is clicked and a search term has been
		   passed as an input.
		*/
		if (this.searchTerm || this.searchTerm === '') {
			this._loadMoreGTMSrv.loadResults(this.searchTerm);
		}
	}
}
