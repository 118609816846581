<section
	aria-hidden="true"
	aria-label="Form not completed"
	class="relative mx-auto bg-white p-6 max-w-[400px] rounded-lg"
>
	<div class="flex items-center justify-center">
		<div class="p-3 bg-[#FEE4E2] rounded-full border-8 border-[#FEF3F2]">
			<uc-exclamation-mark-triangular-svg />
		</div>
	</div>
	@if (currentFormData) {
		<div class="mt-5">
			<div class="flex flex-col items-center">
				<h3 class="mb-2 text-lg font-semibold text-gray-900">
					Unfinished {{ currentFormData.formTitle }}
				</h3>
				<p class="mb-8 text-sm text-center text-gray-500">
					Your {{ currentFormData.formName }} is unfinished, if you exit your
					progress will be lost
				</p>
			</div>
			<div class="flex gap-3">
				<button
					class="w-full secondary-button btn-py-2"
					(click)="onContinueForm()"
				>
					Cancel
				</button>
				<button class="w-full exit-button btn-py-2" (click)="onExitForm()">
					Exit
				</button>
			</div>
		</div>
	}
</section>
