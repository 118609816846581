import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FeaturedPostgraduate, FeaturedUndergraduate } from '@uc/web/shared/data-models';

import { Product } from '@uc/web/shared/data-models';
import { ProspectusBtnComponent } from '../buttons/prospectus-btn/prospectus-btn.component';
import { RequestBtnComponent } from '../buttons/request-btn/request-btn.component';
import { VisitWebsiteBtnComponent } from '../buttons/visit-website-btn/visit-website-btn.component';

@Component({
	selector: 'uc-cta-university-btns',
	standalone: true,
	templateUrl: './cta-university-btns.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [VisitWebsiteBtnComponent, RequestBtnComponent, ProspectusBtnComponent],
})
export class CtaUniversityBtnsComponent {
	@Input({ required: true }) uniName!: string;
	@Input({ required: true }) product: Product | null = null;
	@Input({ required: true }) ugSearchOrderPosition!: number | null;
	@Input() uniId!: number;
	@Input() uniSlug!: string;
	@Input() externalUrl!: string;
	@Input() externalUgProspectusUrl!: string;
	@Input() externalPgProspectusUrl!: string;
	@Input() isFeaturedPostgraduate!: FeaturedPostgraduate;
	@Input() isFeaturedUndergraduate!: FeaturedUndergraduate;
	@Input() privacyPolicyUrl = '';
	@Input() externalProspectusUrl!: string;
}
