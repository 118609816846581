import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CategoryLinkPipe, EntitiesPipe, UcDatePipe } from '@uc/utilities';

@Component({
	selector: 'uc-side-card',
	standalone: true,
	templateUrl: './side-card.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [UcDatePipe, EntitiesPipe, CategoryLinkPipe, RouterModule],
})
export class SideCardComponent implements OnInit {
	@Input() article!: any;
	@Input() categoryUrl!: string;

	articleUrl = '';
	correctCategoryUrl = '';

	ngOnInit(): void {
		this.articleUrl = this.article.url ? this.article.url.split('/') : '';

		const category = this.article.category
			? this.article.category
			: this.article.cat_name;
		this.correctCategoryUrl = category.toLowerCase().split(' ').join('-');
	}
}
