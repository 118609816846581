import {
	AfterViewInit,
	Component,
	ElementRef,
	Inject,
	Input,
	ViewChild,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';

@Component({
	selector: 'uc-split-view-gray-background',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './split-view-gray-background.component.html',
})
export class SplitViewGrayBackgroundComponent implements AfterViewInit {
	@Input() isInAppBrowser = false;
	@Input() customStyles = '';
	@ViewChild('footer') footer!: ElementRef<HTMLElement>;

	currentYear = new Date().getFullYear();

	constructor(@Inject(DOCUMENT) private _document: Document) {}

	ngAfterViewInit(): void {
		const height = this.footer?.nativeElement.offsetHeight;
		this._document?.documentElement.style.setProperty(
			'--secondary-footer-height',
			`${height}px`,
		);
	}
}
