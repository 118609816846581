import { Injectable, makeStateKey } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
	distinctUntilChanged,
	withLatestFrom,
	switchMap,
	catchError,
	of,
	filter,
	map,
} from 'rxjs';
import {
	fetchClientsDropdownList,
	loadClientsDropdownListError,
	updateClientsDropdownList,
} from './clients-dropdown-list.actions';
import { getStateClientsDropdownList } from './clients-dropdown-list.selectors';
import { ClientsDropdownListApiService } from '../clients-dropdown-list-api.service';
import { ClientsDropdownListState } from './clients-dropdown-list.reducer';
import { ClientsDropdownList } from '../models/clients-dropdown-list.models';
import { TransferStateService } from '@uc/utilities';

const TS_CLIENTS_DROPDOWN_LIST = makeStateKey<ClientsDropdownList[]>(
	'ts-clients-dropdown-list',
);

@Injectable()
export class StateClientsDropdownListEffects {
	constructor(
		private readonly actions$: Actions,
		private tsSrv: TransferStateService,
		private store: Store<ClientsDropdownListState>,
		private clientsDropdownListSrv: ClientsDropdownListApiService,
	) {}

	fetchClientsDropdownList = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchClientsDropdownList),
			distinctUntilChanged(),
			withLatestFrom(this.store.select(getStateClientsDropdownList)),
			filter(([, universities]) => !universities || universities.length === 0),
			switchMap(() =>
				this.tsSrv.getData<ClientsDropdownList[]>(
					TS_CLIENTS_DROPDOWN_LIST,
					this.clientsDropdownListSrv.getClientsDropdownList(),
				),
			),
			map((response) => updateClientsDropdownList({ universities: response })),
			catchError((error) => of(loadClientsDropdownListError(error))),
		),
	);
}
