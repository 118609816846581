import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CdnPathPipe } from '@uc/utilities';

@Component({
	selector: 'uc-leads-uni-details-image',
	standalone: true,
	templateUrl: './leads-uni-details-image.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CdnPathPipe, NgOptimizedImage],
})
export class LeadsUniDetailsImageComponent {
	@Input() coverImage!: string;
	@Input() uniName!: string;
	@Input() uniLogo!: string;
	@Input() recommendedPercentage: number | undefined;
	@Input() courseCount = 0;
	@Input() isClearingCourses = false;
}
