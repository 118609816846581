<input
	[checked]="product === clearingType"
	class="relative w-4 h-4 mr-2 cursor-pointer opacity-80 top-px"
	type="checkbox"
	(change)="emitClearing($event)"
	name="universityClearing"
	id="universityClearing"
/>
<label class="text-sm font-medium text-gray-700 cursor-pointer" for="universityClearing">
	{{
		searchType === 'course'
			? 'Show Clearing Courses'
			: 'Show Universities in Clearing'
	}}
</label>
