import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EngageCardCtaImgComponent } from '../engage-card-cta/components/engage-card-cta-img/engage-card-cta-img.component';
import { CdnPathPipe } from '@uc/utilities';
import { NgOptimizedImage } from '@angular/common';
import { DegreeQuizGTMService } from '@uc/web/core';
import { RouterModule } from '@angular/router';

@Component({
	selector: 'uc-engage-card-cta-degree-quiz',
	standalone: true,
	imports: [EngageCardCtaImgComponent, CdnPathPipe, NgOptimizedImage, RouterModule],
	templateUrl: './engage-card-cta-degree-quiz.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngageCardCtaDegreeQuizComponent {
	constructor(private _degreeQuizGTMSrv: DegreeQuizGTMService) {}

	onQuizButtonClick(quizVersion: 'short' | 'long') {
		this._degreeQuizGTMSrv.onQuizButtonClick(quizVersion);
	}
}
