import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FormSubjectOption, SelectOption } from '@uc/web/shared/data-models';
import { EnvironmentApiService } from '@uc/utilities';

@Injectable({
	providedIn: 'root',
})
export class FormOptionsApiService {
	constructor(
		private _http: HttpClient,
		private _apiSrv: EnvironmentApiService,
	) {}

	getSubjects() {
		return this._http.get<FormSubjectOption[]>(this._apiSrv.API_URL + 'subjects');
	}

	getCountries() {
		return this._http.get<SelectOption[]>(this._apiSrv.API_URL + 'countries');
	}

	getRegions(countryId: number) {
		return this._http.get<SelectOption[]>(
			this._apiSrv.API_URL + `countries/${countryId}/regions`,
		);
	}
}
