import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DestructiveActionModalService } from './destructive-action-modal.service';
import { destructiveActionModel } from './destructive-action-modal.model';
import { forms } from './destructive-action-modal.properties';

import { ModalService } from '@uc/shared/ui';
import { ExclamationMarkTriangularSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-destructive-action-modal',
	standalone: true,
	templateUrl: './destructive-action-modal.component.html',
	imports: [ExclamationMarkTriangularSvgComponent],
})
export class DestructiveActionModalComponent implements OnInit {
	currentFormData!: destructiveActionModel;

	constructor(
		private modalSrv: ModalService,
		private router: Router,
		private damSrv: DestructiveActionModalService,
	) {}

	ngOnInit(): void {
		const url = this.router.url.split('?')[0];
		const formData = forms.find((el: destructiveActionModel) => el.url === url);

		if (!formData) {
			return;
		} else {
			this.currentFormData = formData;
		}
	}

	onContinueForm(): void {
		this.damSrv.continueForm();
		this.modalSrv.close();
	}

	onExitForm(): void {
		this.damSrv.exitForm();
		this.modalSrv.close();
	}
}
