<section class="container">
	<div class="divider">
		@if (events.length > 0) {
			<uc-heading
				headingTitle="Upcoming Open Days at {{ uniName }}"
				description=""
			/>
			<div class="grid -mt-4 divide-y sm:gap-6 sm:divide-y-0 sm:mt-0">
				@for (event of events.slice(0, 3); track event) {
					<uc-event-card
						[uniName]="uniName || ''"
						[uniLogo]="uniLogo || ''"
						[slug]="universityUrl"
						[uniId]="uniId"
						[id]="event.id"
						[date]="event.date"
						[description]="event.description"
						[subtitle]="event.subtitle"
						[externalUrl]="event.external_url"
						[product]="product"
						[componentName]="componentName"
						[ugSearchOrderPosition]="ugSearchOrderPosition"
					/>
				}
			</div>
		} @else {
			<uc-no-open-days
				[uniName]="uniName"
				[uniId]="uniId"
				[externalUrl]="externalEventsUrl"
				[product]="product"
				[isFeatured]="true"
				[ugSearchOrderPosition]="ugSearchOrderPosition"
			/>
		}
	</div>
</section>
