<section class="container">
	<div class="pb-8 sm:pb-16 divider-top">
		<h2 class="secondary-header sm:block">
			{{ headerPrefix + universityName + headerSuffix }}
		</h2>
		<div class="grid items-center sm:grid-cols-2 sm:gap-9">
			<!-- Google map location available -->
			<div
				id="bg-location"
				class="z-10 overflow-hidden border rounded-lg h-96 sm:h-72"
				[innerHtml]="googleMap | safe"
				[ngClass]="{ 'bg-location': !googleMap }"
			></div>
			<div
				class="relative py-8 mt-4 border rounded-lg bg-gray-50 sm:mt-0 px-7 md:py-12 sm:px-12"
				[ngClass]="{ 'md:py-14': !address2 }"
			>
				<div class="pb-6 mb-6 border-b">
					<h2 class="text-xl font-bold leading-6 sm:hidden">
						University Address
					</h2>
					<h3 class="hidden text-xl font-bold leading-6 sm:block">
						University Address
					</h3>
					<a
						[attr.href]="
							'https://www.google.com/maps/dir/?api=1&destination=' +
							universityName
						"
						target="”_blank”"
						class="blue-link-underline"
						>Get Directions</a
					>
				</div>
				<address class="not-italic">
					{{ address1 }}<br />
					{{ address2 }}
					@if (address2) {
						<br />
					}
					{{ postcode }}<br />
					{{ country }}<br />
				</address>
			</div>
		</div>
	</div>
</section>
