import { Component, Input } from '@angular/core';

@Component({
	selector: 'uc-clearing-available-badge',
	standalone: true,
	templateUrl: './clearing-available-badge.component.html',
	imports: [],
})
export class ClearingAvailableBadgeComponent {
	@Input({ required: true }) currentClearingYear!: number;
}
