import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Product, UppercaseProduct } from '@uc/web/shared/data-models';
import { BookEventBtnComponent } from '../buttons/book-event-btn/book-event-btn.component';
import {
	AbbreviateButtonTextPipe,
	AbbreviateProductPipe,
	EntitiesPipe,
	UniPipe,
} from '@uc/utilities';

@Component({
	selector: 'uc-event-card',
	standalone: true,
	templateUrl: './event-card.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		EntitiesPipe,
		BookEventBtnComponent,
		CommonModule,
		RouterModule,
		NgOptimizedImage,
		UniPipe,
		AbbreviateProductPipe,
		AbbreviateButtonTextPipe,
	],
})
export class EventCardComponent {
	@Input({ required: true }) uniName!: string;
	@Input({ required: true }) uniLogo!: string | null;
	@Input({ required: true }) slug!: string;
	@Input({ required: true }) uniId!: number;
	@Input({ required: true }) id!: number;
	@Input({ required: true }) date!: string;
	@Input({ required: true }) description!: string;
	@Input({ required: true }) subtitle!: string | null;
	@Input({ required: true }) externalUrl!: string;
	@Input({ required: true }) ugSearchOrderPosition!: number | null;
	@Input({
		required: true,
		transform: (product: UppercaseProduct | Product) => product.toLowerCase(),
	})
	product!: Product;
	@Input({ required: true }) componentName = '';
	@Input() eventType!: string;
}
