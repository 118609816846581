import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentApiService } from '@uc/utilities';
import { ClearingCourseOption } from '@uc/web/shared/data-models';

@Injectable({
	providedIn: 'root',
})
export class ClearingApiService {
	constructor(
		private http: HttpClient,
		private _apiSrv: EnvironmentApiService,
	) {}

	getClearingCoursesForUni(id: number) {
		return this.http.get<ClearingCourseOption[]>(
			`${this._apiSrv.API_URL}clearing/universities/${id}/courses`,
		);
	}
}
