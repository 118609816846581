import { createAction, props } from '@ngrx/store';
import { UniversityEventResponse } from '@uc/web/shared/data-models';

export const fetchAllEvents = createAction(
	'[University Events/API] fetch all university events',
	props<{ uniSlug: string }>(),
);

export const updateAllEvents = createAction(
	'[University Events] update all university events',
	props<{ allEvents: UniversityEventResponse }>(),
);

export const updateAllEventsUniSlug = createAction(
	'[University Events] Update All Events University Slug',
	props<any>(),
);

export const updateIsLoading = createAction('[University Events] Update isLoading state');

export const loadUniversityEventsError = createAction(
	'[University Events] load university events error',
	props<{ error: any }>(),
);
