<section
	aria-hidden="true"
	aria-label="Privacy Policies"
	class="relative mx-auto bg-white rounded-lg shadow-lg md:max-w-max"
>
	<uc-close-modal />
	@if (unisPrivacyPolicies$ | async; as unis) {
		<div class="justify-between p-6 w-full md:w-[512px]">
			<div class="flex items-center justify-between pb-4 border-b">
				<p class="text-lg font-semibold">Privacy Policies</p>
				<uc-close-modal />
			</div>
			<div class="container-max-height">
				@if (unis && unis.length > 0) {
					@for (uni of unis; track uni) {
						<div
							class="flex items-center justify-between gap-4 py-4 border-b md:gap-16"
						>
							<p class="text-base font-bold leading-6 justify-self-start">
								{{ uni.name }}
							</p>
							@if (isInAppBrowser) {
								<button
									type="button"
									(click)="onPrivacyPolicy(uni.privacyPolicy)"
									class="flex-shrink-0 block pr-2 text-sm sm:mr-1 justify-self-end blue-link-underline"
								>
									Privacy Policy
								</button>
							} @else {
								<a
									[attr.href]="uni.privacyPolicy"
									target="_blank"
									rel="noopener"
									class="flex-shrink-0 block pr-2 text-sm sm:mr-1 justify-self-end blue-link-underline"
								>
									Privacy Policy</a
								>
							}
						</div>
					}
				} @else {
					<p
						class="mt-6 leading-6 text-gray-700 text-normal justify-self-start"
					>
						You haven't selected a uni yet.
					</p>
				}
			</div>
		</div>
	}
</section>
