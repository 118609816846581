import { Action, createReducer, on } from '@ngrx/store';
import { fetchPageData, updatePageData, loadPageDataError } from './page-content.actions';
import { PageData } from '../models/page-content.models';

export const _STATE_PAGE_DATA_FEATURE_KEY = 'statePageData';

export interface PageDataState {
	pageData: PageData | null;
	loading: boolean;
	error: any;
}

export const pageDataInitialState: PageDataState = {
	pageData: null,
	loading: false,
	error: '',
};

const reducer = createReducer(
	pageDataInitialState,
	on(fetchPageData, (state) => ({
		...state,
		error: '',
		loading: true,
	})),
	on(updatePageData, (state, { pageData }) => ({
		...state,
		pageData,
		error: '',
		loading: false,
	})),
	on(loadPageDataError, (state, { error }) => ({
		...state,
		error,
		loading: false,
	})),
);

export function pageDataReducer(state: PageDataState | undefined, action: Action) {
	return reducer(state, action);
}
