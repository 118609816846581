<h3 class="-mb-1 text-base font-medium text-center sm:text-left">
	@if (product === 'postgraduate') {
		<a [routerLink]="['/universities', externalUrl, 'postgraduate']">
			{{ uniName }}
		</a>
	} @else {
		@if (externalUrl) {
			<a [routerLink]="['/universities', externalUrl]">
				{{ uniName }}
			</a>
		}
		@if (!externalUrl) {
			<p>{{ uniName }}</p>
		}
	}
</h3>
