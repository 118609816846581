import { createAction, props } from '@ngrx/store';
import { PageData } from '../models/page-content.models';

export const fetchPageData = createAction(
	'[Pages API] fetch page data',
	props<{ url: string }>(),
);

export const updatePageData = createAction(
	'[Pages API] update page data',
	props<{ pageData: PageData | null }>(),
);

export const loadPageDataError = createAction(
	'[Pages API] update page data error',
	props<{ error: any }>(),
);
