<noscript>
	<div
		class="grid justify-center w-full py-8 mx-auto"
		role="navigation"
		aria-label="Page navigation"
	>
		<ul role="presentation" class="inline-flex -space-x-px text-sm">
			<li role="presentation">
				<a
					href="{{ link }}{{ queryParamsUrl }}"
					[ngClass]="{
						disabled: currentPage === 1,
						'rounded-lg mr-2':
							currentPage > 2 || (currentPage === 1 && !hasMorePages),
					}"
					data-test="first"
					class="flex items-center justify-center h-8 px-3 ml-0 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
					>First</a
				>
			</li>
			@if (currentPage > 2) {
				<li role="presentation">
					<a
						href="{{ generateLink('prev') }}"
						data-test="prev-link"
						class="flex items-center justify-center h-8 px-3 ml-0 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
						>Previous</a
					>
				</li>
			}
			@if (hasMorePages) {
				<li role="presentation">
					<a
						href="{{ generateLink('next') }}"
						data-test="next-link"
						class="flex items-center justify-center h-8 px-3 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700"
						>Next</a
					>
				</li>
			}
		</ul>
	</div>
</noscript>
