import { __spreadArray } from "tslib";
import { invariant as originalInvariant, InvariantError } from "ts-invariant";
import { version } from "../../version.js";
import global from "./global.js";
import { stringifyForDisplay } from "../common/stringifyForDisplay.js";
function wrap(fn) {
  return function (message) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      args[_i - 1] = arguments[_i];
    }
    fn.apply(void 0, __spreadArray([typeof message === "number" ? getErrorMsg(message) : message], args, false));
  };
}
var invariant = Object.assign(function invariant(condition, message) {
  var args = [];
  for (var _i = 2; _i < arguments.length; _i++) {
    args[_i - 2] = arguments[_i];
  }
  if (!condition) {
    originalInvariant(condition, getErrorMsg(message, args));
  }
}, {
  debug: wrap(originalInvariant.debug),
  log: wrap(originalInvariant.log),
  warn: wrap(originalInvariant.warn),
  error: wrap(originalInvariant.error)
});
function newInvariantError(message) {
  var optionalParams = [];
  for (var _i = 1; _i < arguments.length; _i++) {
    optionalParams[_i - 1] = arguments[_i];
  }
  return new InvariantError(getErrorMsg(message, optionalParams));
}
var ApolloErrorMessageHandler = Symbol.for("ApolloErrorMessageHandler_" + version);
function getErrorMsg(message, messageArgs) {
  if (messageArgs === void 0) {
    messageArgs = [];
  }
  if (!message) return;
  var args = messageArgs.map(function (arg) {
    return typeof arg == "string" ? arg : stringifyForDisplay(arg, 2).slice(0, 1000);
  });
  return global[ApolloErrorMessageHandler] && global[ApolloErrorMessageHandler](message, args) || "An error occured! For more details, see the full error text at https://go.apollo.dev/c/err#".concat(encodeURIComponent(JSON.stringify({
    version: version,
    message: message,
    args: args
  })));
}
export { invariant, InvariantError, newInvariantError, ApolloErrorMessageHandler };
