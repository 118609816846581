<div class="mb-10">
	<button
		(click)="onLoginClick(); showModalPanel()"
		class="flex justify-center w-full mx-auto !text-base secondary-button"
	>
		<uc-user-icon-outline-svg />
		{{ text }}
	</button>
	<div class="relative mt-10">
		<div class="absolute inset-0 flex items-center">
			<div class="w-full border-t border-gray-300"></div>
		</div>
		<div class="relative flex justify-center text-base">
			<span class="px-4 text-gray-500 bg-white"> OR </span>
		</div>
	</div>
</div>
