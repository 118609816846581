import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserIconOutlineSvgComponent } from '@uc/shared/svg';
import { LoginModalComponent, ModalService } from '@uc/shared/ui';
import { LoginGTMService } from '@uc/web/core';

@Component({
	selector: 'uc-login-to-auto-fill',
	standalone: true,
	imports: [UserIconOutlineSvgComponent],
	templateUrl: './login-to-auto-fill.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginToAutoFillComponent {
	@Input() text = 'Sign In To Auto-Fill Details';

	constructor(
		private modalService: ModalService,
		private loginGTMSrv: LoginGTMService,
	) {}

	showModalPanel() {
		this.modalService.setContent(LoginModalComponent);
		this.modalService.show();
	}

	// GTM event
	onLoginClick = () => this.loginGTMSrv.loginClick();
}
