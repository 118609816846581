import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import {
	FeaturedCalls,
	FeaturedClearingVisitWebsite,
	FeaturedRegisteredInterest,
} from '@uc/web/shared/data-models';
import { VisitWebsiteBtnComponent } from '../../buttons/visit-website-btn/visit-website-btn.component';
import { CallUniBtnComponent } from '../../buttons/call-uni-btn/call-uni-btn.component';
import { RegisterInterestBtnComponent } from '../../buttons/register-interest-btn/register-interest-btn.component';
import { ClearingVisitWebsiteBtnComponent } from '../../buttons/clearing-visit-website-btn/clearing-visit-website-btn.component';
@Component({
	selector: 'uc-clearing-cta-final',
	standalone: true,
	templateUrl: './clearing-cta-final.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		RegisterInterestBtnComponent,
		CallUniBtnComponent,
		VisitWebsiteBtnComponent,
		ClearingVisitWebsiteBtnComponent,
	],
})
export class ClearingCtaFinalComponent {
	uniId = input.required<number>();
	uniName = input.required<string>();
	isFeaturedCalls = input.required<FeaturedCalls>();
	isFeaturedRegisterInterest = input.required<FeaturedRegisteredInterest>();
	isFeaturedClearingVisitWebsite = input.required<FeaturedClearingVisitWebsite>();
	phoneNumber = input.required<string>();
	externalUrl = input.required<string>();
	currentClearingYear = input.required<number>();
	source = input.required<string>();
	cleSearchOrderPosition = input.required<number>();
}
