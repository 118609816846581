<div class="bg-white sm:pb-0">
	<div class="container pt-4 sm:pt-6">
		<div
			class="relative gap-4 py-1 sm:flex sm:flex-wrap sm:items-center sm:justify-between"
		>
			<div class="flex sm:flex-wrap">
				<div
					class="hidden w-16 h-16 px-1 py-3 overflow-hidden bg-white border rounded-lg sm:inline"
				>
					<img
						class="object-contain h-10 w-14"
						width="56"
						height="40"
						[ngSrc]="logo() | cdn"
						[attr.alt]="'Logo of ' + uniName"
					/>
				</div>
				<!-- University or Course name -->
				<ng-content select="[headerTitle]"></ng-content>
			</div>

			<div class="flex gap-3">
				<!-- CTA buttons - displayed on university reviews page -->
				<ng-content select="[ctaButtons]"></ng-content>
				<!-- Save Course Button -->
				<uc-bookmark
					class="hidden sm:block"
					[saved]="saved()"
					[id]="id()"
					[uniName]="uniName()"
					[delta]="delta()"
					[bookmarkBtn]="true"
					componentName="Profile Header"
					[ugSearchOrderPosition]="ugSearchOrderPosition()"
					[cleSearchOrderPosition]="cleSearchOrderPosition()"
					[courseSlug]="courseSlug()"
					[academicYear]="academicYear()"
				/>
			</div>
		</div>
	</div>
	<div class="hidden px-4 bg-white border-b sm:block">
		<div class="container pt-6">
			<div class="-mb-px border-b border-gray-200">
				<!-- Navigation and Bookmark -->
				<ng-content select="[navigation]"></ng-content>
			</div>
		</div>
	</div>
</div>
