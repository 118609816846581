import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
	input,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownDirective, Environment } from '@uc/utilities';
import { Product, ProductEnum } from '@uc/web/shared/data-models';
import {
	SearchSvgComponent,
	ArrowRightSvgComponent,
	ArrowDownSvgComponent,
} from '@uc/shared/svg';
import { LevelSearchBarService } from './level-search-bar.service';
import {
	CourseSearchAutosuggestComponent,
	CourseSearchAutosuggestService,
} from '@uc/web/shared/feature/feature-course-search-autosuggest';

@Component({
	selector: 'uc-level-search-bar',
	standalone: true,
	templateUrl: './level-search-bar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		DropdownDirective,
		FormsModule,
		SearchSvgComponent,
		ArrowRightSvgComponent,
		ArrowDownSvgComponent,
		CourseSearchAutosuggestComponent,
	],
})
export class LevelSearchBarComponent implements OnInit {
	@Input() searchValue = '';
	@Input() selectedProduct: Product = ProductEnum.Undergraduate;
	shouldNavigate = input.required<boolean>();
	placeholder = input('Search');
	autoSuggestEnabled = input(false);
	showClearingInDropdown = input(true);
	showAutoSuggest!: boolean;
	clearingEnabled!: boolean;
	products: Product[] = [ProductEnum.Undergraduate, ProductEnum.Postgraduate];

	@Output() navigate = new EventEmitter<{ product: Product; searchTerm: string }>();

	constructor(
		@Inject('environment') private _environment: Environment,
		private _levelSearchbarSrv: LevelSearchBarService,
		private _autosuggestSrv: CourseSearchAutosuggestService,
	) {
		this.clearingEnabled = this._environment.clearingEnabled;
	}

	ngOnInit(): void {
		if (this.clearingEnabled && this.showClearingInDropdown()) {
			this.products.push(ProductEnum.Clearing);
		}

		this._validateNavigate();
	}

	onChangeStudyType(product: Product): void {
		this.selectedProduct = product;
		this._autosuggestSrv.product.set(product);
	}

	navigateToPage(): void {
		this.showAutoSuggest = false;

		if (this.shouldNavigate()) {
			const params = {
				product: this.selectedProduct,
				searchTerm: this.searchValue,
			};
			this.navigate.emit(params);
			return;
		}
		this._levelSearchbarSrv.setSearchValue(this.searchValue, this.selectedProduct);
	}

	private _validateNavigate() {
		if (this.shouldNavigate() && !this.navigate.observed) {
			console.error(
				'Error: Navigation action not provided. Please provide a navigation action when "shouldNavigate" is set to true.',
			);
		}
	}
}
