import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { CtaCard } from '../clearing/clearing-search/clearing-search.models';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CdnPathPipe } from '@uc/utilities';

@Component({
	selector: 'uc-static-card',
	standalone: true,
	templateUrl: './static-card.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CdnPathPipe, CommonModule, NgOptimizedImage, RouterModule],
})
export class StaticCardComponent {
	@Input() content!: CtaCard;
	@Input() customHeight = 'lg:h-44';
	@Output() cardClick = new EventEmitter<boolean>();

	onClick() {
		this.cardClick.emit(true);
	}
}
