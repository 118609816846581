import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { MinusSvgComponent, PlusSvgComponent } from '@uc/shared/svg';
import { ToggleFaqDirective } from '@uc/utilities';
import {
	CheckBoxEmitData,
	CheckboxOption,
	FilterOption,
} from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-checkbox-select',
	standalone: true,
	imports: [PlusSvgComponent, MinusSvgComponent, ToggleFaqDirective],
	templateUrl: './checkbox-select.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxSelectComponent {
	@Input({ required: true }) filterTitle!: string;
	@Input({ required: true }) filterOptions: FilterOption[] | CheckboxOption[] = [];
	@Input({ required: true }) fieldSetName!: string;
	@Input() inputType: 'checkbox' | 'radio' = 'checkbox';
	@Input() numOfFilters = 0;

	@Output() filterChanged = new EventEmitter<CheckBoxEmitData>();

	optionChecked(event: Event, optionUrl: string) {
		const target = event.target as HTMLInputElement;
		const isChecked = target.checked;
		this.filterChanged.emit({ isChecked, urlValue: optionUrl });
	}
}
