import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'uc-main-header',
	standalone: true,
	imports: [NgClass],
	templateUrl: './main-header.component.html',
})
export class MainHeaderComponent {
	@Input({ required: true }) heading!: string;
	@Input({ required: true }) description!: string;
	@Input({ required: true }) backgroundStyle!: string;
	@Input() textContainerWidth = 'max-w-prose';
	@Input() textColor = 'text-white';
	@Input() descriptionColor = 'text-white';
	@Input() fontSize = 'text-3xl';
	@Input() customPadding = 'lg:py-28';
}
