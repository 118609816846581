import { Action, createReducer, on } from '@ngrx/store';
import {
	fetchClientsDropdownList,
	loadClientsDropdownListError,
	updateClientsDropdownList,
} from './clients-dropdown-list.actions';
import { ClientsDropdownList } from '../models/clients-dropdown-list.models';

export const _STATE_CLIENTS_DROPDOWN_LIST_FEATURE_KEY = 'stateClientsDropdownList';

export interface ClientsDropdownListState {
	universities: ClientsDropdownList[];
	isLoading: boolean;
	error?: any;
}

export interface StateClientsDropdownListPartialState {
	readonly [_STATE_CLIENTS_DROPDOWN_LIST_FEATURE_KEY]: ClientsDropdownListState;
}

const initialState: ClientsDropdownListState = {
	universities: [],
	isLoading: false,
	error: null,
};

const stateReducer = createReducer(
	initialState,
	on(fetchClientsDropdownList, (state) => ({
		...state,
		isLoading: true,
		error: null,
	})),
	on(updateClientsDropdownList, (state, { universities }) => ({
		...state,
		isLoading: false,
		universities,
		error: null,
	})),
	on(loadClientsDropdownListError, (state, { error }) => ({
		...state,
		isLoading: false,
		error,
	})),
);

export function stateClientsDropdownListReducer(
	state: ClientsDropdownListState | undefined,
	action: Action,
) {
	return stateReducer(state, action);
}
