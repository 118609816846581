import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { HeartSvgComponent } from '@uc/shared/svg';
@Component({
	selector: 'uc-bookmark-btn',
	standalone: true,
	templateUrl: './bookmark-btn.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass, HeartSvgComponent],
})
export class BookmarkBtnComponent {
	@Input() saved!: boolean;
	@Input({ required: true }) delta!: 1 | 2;
	@Output() save = new EventEmitter<boolean>();

	onSave() {
		this.save.emit(!this.saved);
	}
}
