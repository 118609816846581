import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoginGTMService } from '@uc/web/core';

import { CloseModalComponent, LoginModalComponent, ModalService } from '@uc/shared/ui';
import { PrimaryBlueBtnComponent } from '../../buttons/primary-blue-btn/primary-blue-btn.component';
import { SecondaryBlueBtnComponent } from '../../buttons/secondary-blue-btn/secondary-blue-btn.component';
import { NotLoggedInErrorSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-not-loggedin-modal',
	standalone: true,
	templateUrl: './not-loggedin-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		SecondaryBlueBtnComponent,
		PrimaryBlueBtnComponent,
		CloseModalComponent,
		NotLoggedInErrorSvgComponent,
	],
})
export class NotLoggedinModalComponent {
	constructor(
		private modalService: ModalService,
		private loginGTMSrv: LoginGTMService,
	) {}

	closeModalPanel() {
		this.modalService.close();
	}

	showModalPanel() {
		this.modalService.setContent(LoginModalComponent);
	}

	// GTM event
	onLoginClick = () => this.loginGTMSrv.loginClick();
}
