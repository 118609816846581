import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateInputComponent, InputFieldComponent } from '@uc/shared/ui';

@Component({
	selector: 'uc-user-details',
	standalone: true,
	templateUrl: './user-details.component.html',
	imports: [InputFieldComponent, DateInputComponent, FormsModule, ReactiveFormsModule],
})
export class UserDetailsComponent {
	@Input() parentForm!: FormGroup;
	@Input() formSubmitted!: boolean;
	@Input() title = '';
	@Input() hidePhone = false;
	@Input() hideCalendar = false;
	@Input() hidePostcode = true;
	@Input() startEndYears!: number[];
}
