<div class="pt-6">
	<div ucToggleFaq>
		<button
			type="button"
			class="flex items-center justify-between w-full only-tab-focus"
			aria-label="Tabs"
		>
			<span
				class="text-base font-medium text-gray-500 capitalize pointer-events-none"
				role="presentation"
			>
				{{ filterTitle }}
				@if (numOfFilters > 0) {
					<span class="pointer-events-none">({{ numOfFilters }})</span>
				}
			</span>
			<div class="text-gray-500 pointer-events-none">
				<uc-plus-svg />
				<uc-minus-svg customStyles="hidden pointer-events-none" />
			</div>
		</button>
	</div>

	<fieldset class="mt-4 space-y-3 rounded-md sr-only">
		@for (option of filterOptions; track option.id) {
			<div class="flex items-center text-gray-700 focus:outline-none">
				<label class="cursor-pointer">
					<input
						[type]="inputType"
						[name]="fieldSetName"
						class="relative w-4 h-4 mr-2 cursor-pointer opacity-80 top-px"
						(change)="optionChecked($event, option.url_name)"
						[checked]="option.checked"
					/>
					<span class="text-sm font-medium text-gray-500">
						{{ option.name }}
						@if (option.count) {
							({{ option.count }})
						}
					</span>
				</label>
			</div>
		}
	</fieldset>

	<div class="w-full mt-6 border-b border-gray-500"></div>
</div>
