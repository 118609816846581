import { Injectable, makeStateKey } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UniversityEventResponse } from '@uc/web/shared/data-models';
import {
	catchError,
	distinctUntilChanged,
	filter,
	map,
	of,
	switchMap,
	tap,
	withLatestFrom,
} from 'rxjs';
import { UniversityEventsAPIService } from '../university-events-api.service';
import * as fromUniversityEventsActions from './university-events.actions';
import { getAllEventsData, getAllEventsUniSlug } from './university-events.selectors';
import { TransferStateService } from '@uc/utilities';

export const TS_UNIVERSITY_EVENTS =
	makeStateKey<UniversityEventResponse>('ts-university-events');

@Injectable()
export class StateUniversityEventsEffect {
	constructor(
		private actions$: Actions,
		private store: Store,
		private tsSrv: TransferStateService,
		private uniEvntsAPISrv: UniversityEventsAPIService,
	) {}

	fetchAllEvents = createEffect(() =>
		this.actions$.pipe(
			ofType(fromUniversityEventsActions.fetchAllEvents),
			withLatestFrom(
				this.store.select(getAllEventsData),
				this.store.select(getAllEventsUniSlug),
			),
			distinctUntilChanged(),
			filter(([payload, eventsData, allEventsUniSlug]) => {
				// continue the stream if no events data
				// or if the current uniSlug !== uniSlug from store
				if (!eventsData || payload.uniSlug !== allEventsUniSlug) {
					return true;
				}
				this.store.dispatch(fromUniversityEventsActions.updateIsLoading());
				// cancel the stream
				return false;
			}),
			tap(([payload]) => {
				this.store.dispatch(
					fromUniversityEventsActions.updateAllEventsUniSlug(payload),
				);
			}),
			switchMap((payload) =>
				this.tsSrv.getData<UniversityEventResponse>(
					TS_UNIVERSITY_EVENTS,
					this.uniEvntsAPISrv.getUniversityEvents(payload[0].uniSlug),
				),
			),
			map((response) =>
				fromUniversityEventsActions.updateAllEvents({
					allEvents: response,
				}),
			),
			catchError((error) =>
				of(fromUniversityEventsActions.loadUniversityEventsError({ error })),
			),
		),
	);
}
