import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { Product, ProductEnum } from '@uc/web/shared/data-models';

@Component({
	selector: 'uc-clearing-checkbox',
	standalone: true,
	imports: [],
	templateUrl: './clearing-checkbox.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClearingCheckboxComponent {
	@Input() product!: Product;
	@Input() searchType!: 'course' | 'university';
	@Output() changeClearingChecked = new EventEmitter<boolean>();

	readonly clearingType = ProductEnum.Clearing;

	emitClearing(event: Event) {
		const target = event.target as HTMLInputElement;
		const value = target.checked;
		this.changeClearingChecked.emit(value);
	}
}
