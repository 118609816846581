<!-- external -->
@if (content.external) {
	<a
		[attr.href]="content.link"
		target="_blank"
		rel="noopener sponsored"
		class="relative block w-full h-64"
		[ngClass]="customHeight"
		(click)="onClick()"
	>
		<img
			[ngSrc]="content.image | cdn"
			fill
			[attr.alt]="'Image of ' + content.title"
			class="object-cover pointer-events-none rounded-2xl"
		/>
	</a>
} @else {
	<!-- internal -->
	<a
		[routerLink]="content.link"
		[queryParams]="content.params"
		queryParamsHandling="merge"
		class="relative block w-full h-64"
		[ngClass]="customHeight"
		(click)="onClick()"
	>
		<img
			[ngSrc]="content.image | cdn"
			fill
			alt=""
			class="object-cover pointer-events-none rounded-2xl"
		/>
	</a>
}

<h3 class="mt-4 text-lg font-bold text-gray-900">
	@if (content.external) {
		<a
			[attr.href]="content.link"
			target="_blank"
			rel="noopener sponsored"
			(click)="onClick()"
			>{{ content.title }}</a
		>
	} @else {
		<a
			[routerLink]="content.link"
			[queryParams]="content.params"
			(click)="onClick()"
			queryParamsHandling="merge"
		>
			{{ content.title }}
		</a>
	}
</h3>

<p class="mt-2 text-base text-gray-500">
	{{ content.desc }}
</p>
