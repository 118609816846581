import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CtaCard } from './clearing-search.models';
import { getContent } from './clearing-search.utils';
import { HeadingComponent } from '@uc/shared/ui';
import { StaticCardComponent } from '../../static-cards/static-card.component';

@Component({
	selector: 'uc-clearing-search',
	standalone: true,
	templateUrl: './clearing-search.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [HeadingComponent, StaticCardComponent],
})
export class ClearingSearchComponent implements OnInit {
	@Input() year = new Date().getFullYear();
	content: CtaCard[] = [];

	ngOnInit() {
		this.content = getContent(this.year);
	}
}
