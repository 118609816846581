import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

type LoadMoreBtn = {
	page: number;
	fetchData: boolean;
	ignoreEmission: boolean;
};

@Injectable({
	providedIn: 'root',
})
export class LoadMoreBtnService {
	private page = new Subject<LoadMoreBtn>();
	getLoadMorePage$ = this.page.asObservable();

	setInitialPage(page: number) {
		this.page.next({
			page: page,
			fetchData: false,
			ignoreEmission: true,
		});
	}

	nextPage(page: number) {
		this.page.next({
			page: page,
			fetchData: true,
			ignoreEmission: false,
		});
	}

	resetPage(fetchData = true, ignoreEmission = false) {
		this.page.next({
			page: 1,
			fetchData,
			ignoreEmission,
		});
	}
}
