import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import { Params, RouterModule } from '@angular/router';
import {
	ClientValidatorService,
	CourseGTMService,
	ImpressionDirective,
	UniversityGTMService,
} from '@uc/web/core';
import {
	ClientFeatureOptions,
	ClientFeatures,
	FeaturedClearing,
	ProductEnum,
} from '@uc/web/shared/data-models';
import { ClearingVisitWebsiteBtnComponent } from '../../buttons/clearing-visit-website-btn/clearing-visit-website-btn.component';
import { Location, NgOptimizedImage } from '@angular/common';
import { CdnPathPipe } from '@uc/utilities';
import { BookmarkComponent } from '../../bookmark/bookmark.component';
import { SecondaryBlueBtnComponent } from '../../buttons/secondary-blue-btn/secondary-blue-btn.component';
import { WriteReviewBtnComponent } from '../../buttons/write-review-btn/write-review-btn.component';
import { CtaClearingUniversityBtnsComponent } from '../../cta-clearing-university-btns/cta-clearing-university-btns.component';
import { getUniCourseSearchParams } from '../../university-card/university-card.utils';

@Component({
	selector: 'uc-clearing-university-card',
	standalone: true,
	imports: [
		CdnPathPipe,
		ClearingVisitWebsiteBtnComponent,
		SecondaryBlueBtnComponent,
		CtaClearingUniversityBtnsComponent,
		BookmarkComponent,
		ImpressionDirective,
		NgOptimizedImage,
		RouterModule,
		WriteReviewBtnComponent,
	],
	templateUrl: './clearing-university-card.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClearingUniversityCardComponent implements OnInit {
	uniName = input.required<string>();
	uniId = input.required<number>();
	uniSlug = input.required<string>();
	uniCover = input.required<string>();
	uniLogo = input.required<string>();
	clientFeatures = input.required<ClientFeatureOptions[]>();
	recommended = input.required<number | undefined>();
	saved = input.required<boolean>();
	displaySeeCoursesBtn = input.required<boolean>();
	cleSearchOrderPosition = input.required<number>();
	source = input<string>();
	externalUrl = input<string>('');
	searchTerm = input<string>('');
	displaySearchTerm = input<boolean>();
	phoneNumber = input<string>('');
	isFeatured!: FeaturedClearing;
	internalUniUrl!: string;
	queryParams: Params = {};

	readonly clearing = ProductEnum.Clearing;

	constructor(
		public cvSrv: ClientValidatorService,
		private _location: Location,
		private _uniGTMSrv: UniversityGTMService,
		private _courseGTMSrv: CourseGTMService,
	) {}

	ngOnInit(): void {
		this.isFeatured = this.cvSrv.checkFeature(
			this.clientFeatures(),
			ClientFeatures.Clearing,
		);

		this.internalUniUrl = `/universities/${this.uniSlug()}/clearing`;
		this.prepareQueryParams();
	}

	prepareQueryParams() {
		if (this.source() === 'universities') {
			this.queryParams = {
				uni: this.uniSlug(),
			};
		} else {
			const params = getUniCourseSearchParams(this._location.path());
			this.queryParams = {
				...params,
				uni: this.uniSlug(),
			};
		}
	}

	// GTM event
	onUniProfileClick() {
		this._uniGTMSrv.uniProfileClick(
			this.uniId(),
			this.uniName(),
			null,
			this.cleSearchOrderPosition(),
			this.clearing,
		);
	}

	onUniImpression(impression: any) {
		if (impression) {
			this._uniGTMSrv.uniImpression(
				this.uniId(),
				this.uniName(),
				null,
				this.cleSearchOrderPosition(),
				this.clearing,
			);
		}
	}

	/*
	   GTM Event when the See all courses button is clicked.
	   Should work when either a search term has been entered or no search term
	   has been entered.
	*/
	onViewMoreCourses() {
		this._courseGTMSrv.viewMore(this.uniId(), this.uniName(), this.searchTerm());
	}
}
