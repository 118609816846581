import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	PersonalStatementKeywordsState,
	_STATE_PERSONAL_STATEMENT_KEYWORDS_FEATURE_KEY,
} from './personal-statement-keywords.reducer';

const getStatePersonalStatementKeywordsState =
	createFeatureSelector<PersonalStatementKeywordsState>(
		_STATE_PERSONAL_STATEMENT_KEYWORDS_FEATURE_KEY,
	);

export const getPersonalStatementKeywords = createSelector(
	getStatePersonalStatementKeywordsState,
	(state: PersonalStatementKeywordsState) => state.keywords,
);

export const getPersonalStatementKeywordsAndCategory = createSelector(
	getStatePersonalStatementKeywordsState,
	(state: PersonalStatementKeywordsState) => ({
		keywords: state.keywords,
		category: state.category,
	}),
);

export const getPersonalStatementCategory = createSelector(
	getStatePersonalStatementKeywordsState,
	(state: PersonalStatementKeywordsState) => state.category,
);
