import { Action, createReducer, on } from '@ngrx/store';
import {
	fetchPersonalStatementKeywords,
	loadStatePersonalStatementKeywordsFailure,
	resetStateOnNotFound,
	updatePersonalStatementCategory,
	updatePersonalStatementKeywords,
} from './personal-statement-keywords.actions';
import { StatementKeyword } from '../models/personal-statement-keywords.models';

export const _STATE_PERSONAL_STATEMENT_KEYWORDS_FEATURE_KEY =
	'statePersonalStatementKeywords';

export interface PersonalStatementKeywordsState {
	keywords: StatementKeyword[] | null;
	category: string | null;
	isLoading: boolean;
	error?: string | null;
}

export interface StatePersonalStatementKeywordsPartialState {
	readonly [_STATE_PERSONAL_STATEMENT_KEYWORDS_FEATURE_KEY]: PersonalStatementKeywordsState;
}

const initialState: PersonalStatementKeywordsState = {
	keywords: null,
	category: null,
	isLoading: false,
	error: null,
};

const statePersonalStatementKeywordsReducer = createReducer(
	initialState,
	on(fetchPersonalStatementKeywords, (state) => ({
		...state,
		isLoading: true,
		error: null,
	})),

	on(updatePersonalStatementKeywords, (state, { keywords }) => ({
		...state,
		keywords: keywords,
		isLoading: false,
		error: null,
	})),

	on(updatePersonalStatementCategory, (state, { category }) => ({
		...state,
		category: category,
		isLoading: false,
		error: null,
	})),

	on(resetStateOnNotFound, (state) => ({
		...state,
		keywords: null,
		category: null,
		isLoading: false,
	})),

	on(loadStatePersonalStatementKeywordsFailure, (state, { error }) => ({
		...state,
		isLoading: false,
		error: error,
	})),
);

export function personalStatementKeywordsReducer(
	state: PersonalStatementKeywordsState | undefined,
	action: Action,
) {
	return statePersonalStatementKeywordsReducer(state, action);
}
