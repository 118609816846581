<span
	class="inline-flex items-center px-3 py-2 text-sm border border-gray-200 rounded-full whitespace-nowrap hover:text-gray-900 only-tab-focus"
>
	{{ pillLabel }}
	@if (!removeActionDisabled) {
		<button
			type="button"
			(click)="removeFilter.emit({ urlKey, urlValue })"
			class="inline-block ml-3 text-gray-500 hover:text-gray-900 only-tab-focus"
		>
			<uc-close-modal-svg customStyles="!h-4 !w-4" />
		</button>
	}
</span>
