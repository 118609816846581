import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	_STATE_RECOMMENDED_UNIVERSITIES_FEATURE_KEY,
	RecommendedUniversitiesState,
} from './recommended-universities.reducer';

// Lookup the 'StateRecommendedUniversities' feature state managed by NgRx
export const getStateRecommendedUniversitiesState =
	createFeatureSelector<RecommendedUniversitiesState>(
		_STATE_RECOMMENDED_UNIVERSITIES_FEATURE_KEY,
	);

export const getStateRecommendedUniversitiesLoaded = createSelector(
	getStateRecommendedUniversitiesState,
	(state: RecommendedUniversitiesState) => state.loaded,
);

export const getStateRecommendedUniversitiesError = createSelector(
	getStateRecommendedUniversitiesState,
	(state: RecommendedUniversitiesState) => state.error,
);

export const getStateRecommendedUniversities = createSelector(
	getStateRecommendedUniversitiesState,
	(state: RecommendedUniversitiesState) => state.recommendedUniversities,
);

export const getStateRecommendedUniversitiesUndergraduate = createSelector(
	getStateRecommendedUniversitiesState,
	(state: RecommendedUniversitiesState) => state.recommendedUniversities?.undergraduate,
);

export const getStateRecommendedUniversitiesPostgraduate = createSelector(
	getStateRecommendedUniversitiesState,
	(state: RecommendedUniversitiesState) => state.recommendedUniversities?.postgraduate,
);

export const getStateRecommendedClearingUniversities = createSelector(
	getStateRecommendedUniversitiesState,
	(state: RecommendedUniversitiesState) => state.recommendedUniversities?.clearing,
);
