import { createAction, props } from '@ngrx/store';
import { StatementKeyword } from '../models/personal-statement-keywords.models';

export const fetchPersonalStatementKeywords = createAction(
	'[StatePersonalStatementKeywords/API] Fetch Personal Statement Keywords',
	props<{ category: string }>(),
);

export const updatePersonalStatementKeywords = createAction(
	'[StatePersonalStatementKeywords] Update Personal Statement Keywords',
	props<{ keywords: StatementKeyword[] }>(),
);

export const updatePersonalStatementCategory = createAction(
	'[StatePersonalStatementKeywords] Update Personal Statement Category',
	props<{ category: string }>(),
);

export const resetStateOnNotFound = createAction(
	'[StatePersonalStatementKeywords] Reset State On Not Found',
);

export const loadStatePersonalStatementKeywordsFailure = createAction(
	'[StatePersonalStatementKeywords] Load Personal Statement Keywords Failure',
	props<{ error: any }>(),
);
