<button type="button" class="flex items-center" (click)="onSave()">
	<uc-heart-svg
		[customStyles]="
			!saved
				? 'fill-gray-300 unsaved-heart-custom-fill-opacity stroke-white stroke-2'
				: 'fill-uc-red-600'
		"
	>
	</uc-heart-svg>
</button>
