<a
	(click)="onRequestInfoClick()"
	[ngClass]="customStyles"
	class="inline-flex items-center justify-center whitespace-nowrap primary-green-btn focus:outline-none sm:w-auto"
	routerLink="/request"
	[queryParams]="{ uni: params }"
	data-test-id="request-info-btn"
>
	{{ buttonText }}
</a>
