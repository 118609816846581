import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UniversityEvent, UppercaseProductEnum } from '@uc/web/shared/data-models';

import {
	_STATE_UNIVERSITY_EVENTS,
	UniversityEventsState,
} from './university-events.reducer';

export const getUniversityEventsState = createFeatureSelector<UniversityEventsState>(
	_STATE_UNIVERSITY_EVENTS,
);

export const getUndergraduateEventsData = createSelector(
	getUniversityEventsState,
	(state: UniversityEventsState) =>
		filterEvents(
			state.allEvents.universityEvents,
			UppercaseProductEnum.Undergraduate,
		),
);

export const getPostgraduateEventsData = createSelector(
	getUniversityEventsState,
	(state: UniversityEventsState) =>
		filterEvents(state.allEvents.universityEvents, UppercaseProductEnum.Postgraduate),
);

export const getAllEventsData = createSelector(
	getUniversityEventsState,
	(state: UniversityEventsState) => state.allEvents,
);

export const getAllEventsUniSlug = createSelector(
	getUniversityEventsState,
	(state: UniversityEventsState) => state.allEventsUniSlug,
);

export const getUniversityEventsStateIsLoading = createSelector(
	getUniversityEventsState,
	(state: UniversityEventsState) => state.isLoading,
);

const filterEvents = (events: UniversityEvent[], product: string) => {
	return events?.filter((event: UniversityEvent) => event.product === product);
};
