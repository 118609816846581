<section
	aria-hidden="true"
	aria-label="Order Prospectus"
	class="relative mx-auto bg-white rounded-lg shadow-lg max-w-max"
>
	<uc-close-modal />
	@if (modalType$ | async; as modalType) {
		<div class="flex flex-col items-center justify-center p-6 max-w-[400px]">
			<div class="flex flex-col items-center justify-center text-center">
				<uc-book-within-sphere-svg />
				<!-- pg prospectus only modal -->
				@if (modalType === 'internal') {
					<h3 class="mt-5 mb-2 text-lg font-semibold leading-7 text-gray-900">
						Order postgraduate prospectuses
					</h3>
					<p class="mb-8 text-base leading-6 text-gray-500">
						This uni doesn't offer undergraduate prospectuses. By clicking
						below, you'll be ordering a postgraduate prospectus.
					</p>
					<button
						type="button"
						(click)="redirectToOrderPage()"
						class="block w-full get-prospectus-btn"
					>
						Get Postgraduate Prospectus
					</button>
				}
				<!-- external link modal -->
				@if (modalType === 'external' && (externalLinks$ | async); as links) {
					<h3 class="mt-5 mb-2 text-lg font-semibold leading-7 text-gray-900">
						Order {{ getProspectusProductName(links) }} prospectuses
					</h3>
					<p class="mb-8 text-base leading-6 text-gray-500">
						<!-- logged in user -->
						@if (isLoggedIn) {
							To order your prospectus for this uni, we’ll take you to their
							site. Your profile data will be shared with them. Happy to
							continue? Click below!
							@if (privacyPolicyUrl$ | async; as policy) {
								<a
									[attr.href]="policy"
									class="underline blue-link"
									rel="noopener"
									target="_blank"
								>
									See University Privacy Policy</a
								>
							}
						}
						<!-- not logged in -->
						@if (!isLoggedIn) {
							Clicking either button will take you to the university’s
							prospectus order page.
						}
					</p>
					<!-- action buttons -->
					<div class="flex flex-col w-full gap-3">
						@for (link of links; track link; let i = $index) {
							@if (link) {
								<a
									[attr.href]="link"
									target="_blank"
									rel="noopener sponsored"
									(click)="trackExternalPageClick(i)"
									class="block w-full"
									[ngClass]="{
										'get-prospectus-btn': i === 0 || !links[0],
										'secondary-button': i === 1 && links[0],
									}"
								>
									Get
									{{ i === 0 ? 'Undergraduate' : 'Postgraduate ' }}
									Prospectus
								</a>
							}
						}
					</div>
				}
			</div>
		</div>
	}
</section>
