<header class="relative py-8" [id]="backgroundStyle" [ngClass]="customPadding">
	<div class="container">
		<div class="justify-between gap-6">
			<div class="w-full max-w" [ngClass]="textContainerWidth">
				<div class="z-10" [ngClass]="textColor">
					<h1 class="mb-3 font-bold" [ngClass]="fontSize">
						{{ heading }}
					</h1>
					<p class="text-base" [ngClass]="descriptionColor">
						{{ description }}
					</p>
				</div>
				<ng-content></ng-content>
			</div>
		</div>
	</div>
</header>
