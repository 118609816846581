@if (title) {
	<h2 class="mb-6 text-xl font-semibold whitespace-nowrap">
		{{ title }}
	</h2>
}

<form [formGroup]="parentForm">
	<div formGroupName="userDetailsForm" class="grid gap-6 mt-8 sm:mt-6">
		<div class="grid gap-6 lg:grid-cols-2">
			<uc-input-field
				formControlName="firstname"
				inputType="text"
				controlName="firstname"
				placeholder="First Name*"
				autocomplete="given-name"
				labelText="First Name"
				[formSubmitted]="formSubmitted"
			>
			</uc-input-field>
			<uc-input-field
				formControlName="lastname"
				inputType="text"
				controlName="lastname"
				placeholder="Last Name*"
				autocomplete="family-name"
				labelText="Last Name"
				[formSubmitted]="formSubmitted"
			>
			</uc-input-field>
		</div>
		<uc-input-field
			formControlName="email"
			inputType="email"
			controlName="email"
			placeholder="Email Address*"
			autocomplete="email"
			labelText="Email Address"
			[formSubmitted]="formSubmitted"
		>
		</uc-input-field>
		@if (!hidePhone) {
			<uc-input-field
				formControlName="phone"
				inputType="text"
				controlName="phone"
				placeholder="Mobile Number*"
				autocomplete="tel"
				labelText="Mobile Number"
				[formSubmitted]="formSubmitted"
			>
			</uc-input-field>
		}
		@if (!hideCalendar) {
			<uc-date-input
				formControlName="birthdate"
				controlName="birthdate"
				[formSubmitted]="formSubmitted"
			>
			</uc-date-input>
		}
		@if (!hidePostcode) {
			<uc-input-field
				formControlName="postcode"
				inputType="text"
				controlName="postcode"
				placeholder="Postcode"
				autocomplete="postal-code"
				labelText="Postcode"
				[formSubmitted]="formSubmitted"
			>
			</uc-input-field>
		}
	</div>
</form>
