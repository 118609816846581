<div class="relative w-full mt-4 md:mt-6 h-72">
	<img
		[ngSrc]="coverImage | cdn"
		fill
		[attr.alt]="'Image of ' + uniName"
		class="object-cover rounded-lg"
	/>
	<div
		class="absolute bottom-0 left-0 flex items-center w-full gap-3 px-5 py-4 rounded-b-lg glass-overlay"
	>
		<div class="relative flex-shrink-0 w-12 h-12 bg-white rounded-lg">
			<img
				[ngSrc]="uniLogo | cdn"
				fill
				[attr.alt]="'Logo of ' + uniName"
				class="object-contain rounded-lg"
			/>
		</div>
		<div class="flex flex-col">
			<p class="text-base font-bold leading-6 text-white">{{ uniName }}</p>
			<p class="text-sm font-normal leading-[21px] text-white">
				@if (recommendedPercentage) {
					{{ recommendedPercentage }}% Recommended
				}
				@if (courseCount) {
					@if (recommendedPercentage) {
						&middot;
					}
					{{ courseCount }}
					@if (isClearingCourses) {
						<span class="hidden md:inline">clearing</span>
					}
					courses
				}
			</p>
		</div>
	</div>
</div>
