import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentApiService } from '@uc/utilities';

@Injectable({ providedIn: 'root' })
export class AdviceArticleApiService {
	constructor(
		private _http: HttpClient,
		private _apiSrv: EnvironmentApiService,
	) {}

	getIndividualArticle(id: number) {
		const params = {
			artid: id,
		};

		return this._http.post(this._apiSrv.PHP_URL + 'Articles/uc_art_get.php', params);
	}
}
