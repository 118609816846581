import { destructiveActionModel } from './destructive-action-modal.model';

export const forms: destructiveActionModel[] = [
	{
		url: '/register',
		formTitle: 'Registration',
		formName: 'registration form',
	},
	{
		url: '/register/step-2',
		formTitle: 'Registration',
		formName: 'registration form',
	},
	{
		url: '/request',
		formTitle: 'Request',
		formName: 'university request',
	},
	{
		url: '/order',
		formTitle: 'Order',
		formName: 'prospectus order',
	},
	{
		url: '/submit-review',
		formTitle: 'Review',
		formName: 'university review',
	},
	{
		url: '/register-interest',
		formTitle: 'Registration',
		formName: 'interest registration',
	},
];
