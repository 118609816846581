import { Component } from '@angular/core';

import { PrivacyPoliciesModalService } from './privacy-policies-modal.service';
import { CloseModalComponent } from '@uc/shared/ui';
import { InAppBrowserMessageHandlerService } from '@uc/web/core';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'uc-privacy-policies-modal',
	standalone: true,
	imports: [CloseModalComponent, AsyncPipe],
	templateUrl: './privacy-policies-modal.component.html',
})
export class PrivacyPoliciesModalComponent {
	unisPrivacyPolicies$ = this._privacyPoliciesModalSrv.selectedUnis$;
	isInAppBrowser = this._privacyPoliciesModalSrv.getIsInAppBrowser();

	constructor(
		private _privacyPoliciesModalSrv: PrivacyPoliciesModalService,
		private _inAppBrowserMessageHandlerSrv: InAppBrowserMessageHandlerService,
	) {}

	onPrivacyPolicy(link: string) {
		this._inAppBrowserMessageHandlerSrv.ios.onPrivacyPolicy(link);
		this._inAppBrowserMessageHandlerSrv.android.onPrivacyPolicy(link);
	}
}
